.alert
  font-size: 0.875rem
  text-align: left
  display: table
  line-height: 1.3
  width: 100%

.alert
  .pp-ico
    display: table-cell
    vertical-align: middle
    +rem('padding-right', 10px)
    width: 1rem

.alert-warning
  .pp-ico
    color: $warning-icon

.alert-danger
  .pp-ico
    color: $danger-icon

.offline-msg
  .alert
    margin: 0

.no-data-msg
  text-align: center
  color: $pp-gray
  margin: 3rem 0
  font-size: .8rem

.pending-verification
  text-transform: none
  font-weight: 500
  color: $pp-orange

.alert-position-center
  margin: 0 auto
  position: absolute
  right: 0
  left: 0
  width: auto

.primary-contact
  text-transform: none
  font-weight: 500
  color: var(--bs-alert-color)
