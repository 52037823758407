.preloader-wrapper-pp,
.preloader-wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: 0 auto
  min-height: 20rem
  &.amount
    min-height: auto
    height: 12px!important
    position: relative
    display: inline-flex
    margin: 0 .2rem
    min-width: 1rem
  &.cropper
    width: initial!important
    min-height: initial

.loader -placeholder
  opacity: 0.75
  &.pp-masonry
    margin: 0 0 7px 0

.loader
  &-lg,
  &-md
    &__svg
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
      fill: none
      stroke-width: 3px
      stroke-linecap: round
      stroke: var(--loader-svg-color)
      &.bg
        width: 3px
        stroke: var(--loader-svg-color)
      &.animate
        dasharray: 242.6
        animation: loader 1s cubic-bezier(1, 1, 1, 1) 0s infinite
        stroke: var(--loader-bg-color)
    .cls-1, .cls-2
      fill: var(--loader-svg-color) !important

.loader-sm
  &__svg
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    fill: none
    stroke-width: 3px
    stroke-linecap: round
    stroke: var(--loader-svg-color)
    &.bg
      width: 3px
      stroke: var(--loader-bg-color)
    &.animate
      dasharray: 242.6
      animation: loader 1.2s cubic-bezier(1, 1, 1, 1) 0s infinite
  .cls-1, .cls-2
    fill: var(--loader-svg-color)
