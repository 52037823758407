.fieldset-label
  margin-top: 1.5rem
  position: relative
  label
    position: absolute
    top: -.6rem
    width: auto
    padding: 0 .25rem
    margin-left: .5rem
    z-index: 2
    background-color: var(--form-label-bg)
    white-space: nowrap
    text-transform: none
    margin-bottom: 1rem
    color: var(--form-label-color)

.region-select
  .fieldset-label
    margin-bottom: 0
