input[type="search"]::-webkit-search-cancel-button
  appearance: none
.header-middle-wrapper
  display: flex
  flex-direction: row
  vertical-align: middle
  width: 100%
  .header-middle-input
    width: 100%
    display: flex
    align-items: center
    position: relative
    .Select-input
      width: 100%
      top: -1px
      padding-right: 7.5rem
      input
        width: 97% !important
        color: $pp-black
    .Select-control
      background-color: $pp-white
      height: $form-input-height
      display: flex
      align-items: center
    .Select.is-focused:not(.is-open) > .Select-control
      border-color: $pp-header-search
    .Select-loading
      border: 2px solid $inner-circle
      border-right-color: $outer-circle
    .Select-loading-zone
      margin: 0 auto
    .Select-placeholder
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      color: $pp-input-placeholder
      font-size: .95rem
      font-style: normal
      display: flex
      align-items: center
    .Select-menu
      max-height: initial
      overflow: visible
    .Select-menu-outer
      margin: 0 0 .15rem 0
      border: none
      position: static
      max-height: $search-results-height
      overflow-y: scroll
      border-radius: 0 0 $btn-border-radius $btn-border-radius
    .Select-option
      .pp-category
        text-align: center
      &:hover
        background-color: var(--header-search-hover-bg)
      &.classified
        text-transform: initial
        letter-spacing: 0
        line-height: 1
        color: $pp-gray
        b
          display: block
          color: var(--form-input-color)
        .img-wrapper
          width: 3.125rem
          margin: .2rem .5rem .2rem 0
          text-align: center
          .image-placeholder
            position: relative
            overflow: hidden
            border-radius: .2rem
            height: 2.25rem
            margin-right: .5rem
            text-align: center
            display: flex
            flex-direction: column
            justify-content: center
            width: 3.125rem
            background-color: var(--pp-bg-gray)
          img
            +rem("max-width", 50px)
            +rem("max-height", 50px)
            border-radius: .2rem
      &:last-child
        border-radius: 0
    .search-content
      span
        word-break: break-word
        line-height: 1.2
        color: var(--header-search-color)
    .price
      margin-left: auto
      font-weight: bold
      white-space: nowrap
      color: var(--masonry-price)
      position: relative
    .old-price
      bottom: 1rem
      font-size: .75rem
    .count
      margin-left: auto
      color: $pp-gray
    .header-search
      align-items: center
      display: flex
      width: 100%
    .automatic-values
      position: absolute
      top: 0
      left: 65px
      transform: translateY(0)
      pointer-events: none
      font-weight: 300
      color: $text-gray
      overflow: hidden
      height: $form-input-height
      .search__suggestion
        position: relative
        height: $form-input-height
        overflow: hidden
        p
          position: absolute
          bottom: .5rem
          transform: translateY(100%)
          opacity: 0
          animation: slideUp 4s infinite
          margin: 0
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
    .btn
      margin-right: .7rem
      display: flex
      align-items: center
      justify-content: center
      min-width: inherit
      overflow: visible
    .search-btn-wrapper
      top: .05rem
      position: absolute
      right: .3rem
      display: flex
      align-items: center
      +rem("height", 38px)
      i.pp-close
        color: $gray-828081
    .btn
      &.btn-search
        height: 2rem
        padding: 0.5rem 0.7rem
        z-index: 5
        margin: 0
        border: none
        min-width: auto
    input
      color: var(--form-input-color)
      background: var(--form-input-bg)
      padding: 0 8rem 0 .75rem
      border: none
      border-radius: 0
      height: $form-input-height
    .search-main
      z-index: 5
      flex-direction: column
      //width: 100%
      &.highlight
        .Select-control
          border: 1px solid $pp-yellow
      i.pp-ico
        &:hover
          opacity: 1
    .auto-complete
      position: absolute
      right: 0
      left: 0
      background-color: $pp-white
      border-radius: .2rem
      i.pp-ico
        color: $pp-white
        opacity: initial
      .btn
        border-top-right-radius: 0
        border-top-left-radius: 0
        background-color: $pp-yellow
        border-color: $pp-yellow
        margin: 0
        &:hover
          background-color: darken($pp-yellow, 10%)

.search-btn-loader
  position: absolute
  top: 0.45rem
  left: 0.35rem

.ac-open
  .header-middle-input
    flex-direction: column
    .header-search
      position: absolute
      z-index: 99999
      top: .75rem
  .btn-create
    display: none!important

.btn-create
  span
    width: 9.25rem
    height: 14px
    overflow: hidden
  i
    width: 1.25rem

.btn-see-all
  border-top: 1px solid $pp-white
  margin-left: 0!important
