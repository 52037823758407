//
// Control
// ------------------------------


.Select
  position: relative

  // disable some browser-specific behaviours that break the input
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button
    display: none !important

  input::-ms-clear
    display: none !important

  input::-ms-reveal
    display: none !important

  // preferred box model

  // handle disabled state
  &.is-disabled
    .Select-arrow-zone
      cursor: default
      pointer-events: none


      &:hover
        box-shadow: none

  &.is-searchable
    &.is-open > .Select-control
      cursor: text

    &.is-focused:not(.is-open) > .Select-control
      cursor: text

  &.is-open > .Select-control
   // +border-bottom-radius(0)


    // flip the arrow so its pointing up when the menu is open
    .Select-arrow
      top: -2px


  &.has-value.Select--single > .Select-control .Select-value,
  &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value

    a.Select-value-label
      cursor: pointer
      text-decoration: none

      &:hover,
      &:focus
        outline: none
        text-decoration: underline


  // fake-hide the input when the control is pseudo-focused
  &.has-value.is-pseudo-focused .Select-input
    opacity: 0

  &.Select--rtl
    direction: rtl
    text-align: right

// base

.Select-control
  cursor: default
  display: table
  outline: none
  overflow: hidden
  position: relative
  width: 100%
  font-weight: normal
  font-style: italic

  .Select-input:focus
    outline: none

// placeholder
.Select-placeholder,
.Select--single > .Select-control .Select-value
  text-transform: none
  bottom: 0
  left: 0
  padding: $input-padding-y
  position: absolute
  right: 0
  top: 0
  font-size: 1.1rem

  // crop text
  max-width: 100%
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

// the <input> element users type in

.Select-input
  // inherits `display: inline-block` from "react-input-autosize"
  vertical-align: middle
  position: absolute
  top: 0

  > input
    width: 100%
    background: none transparent
    border: 0 none
    box-shadow: none
    cursor: default
    display: inline-block
    font-family: inherit
    font-size: inherit

    // height: $select-input-internal-height;
    margin: 0
    outline: none

    // padding: 0;
    line-height: 17px

    /* For IE 8 compatibility
    padding: .85rem 2.8rem .85rem .75rem

    /* For IE 8 compatibility
    -webkit-appearance: none

    font-weight: bold

    .is-focused &
      cursor: text

// fake input
.Select-control:not(.is-searchable) > .Select-input
  outline: none

// loading indicator
.Select-loading-zone
  cursor: pointer
  display: table-cell
  position: relative
  text-align: center
  vertical-align: middle



// the little cross that clears the field



.Select-clear
  display: inline-block
  line-height: 1


.Select--multi .Select-multi-value-wrapper
  display: inline-block

.Select-multi-value-wrapper
  margin-right: auto
  .selected-category
    font-weight: bold !important
    background: none !important
    font-style: normal
    align-items: center
  span
    font-size: 1rem
    font-weight: bold
    line-height: 1.5
    cursor: pointer
    height: 2.75rem
    overflow: hidden
    display: flex
    align-items: center
    padding-left: .75rem

.Select .Select-aria-only
  position: absolute
  display: inline-block
  height: 1px
  width: 1px
  margin: -1px
  clip: rect(0, 0, 0, 0)
  overflow: hidden
  float: left

// arrow indicator

.Select-arrow-zone
  cursor: pointer
  display: table-cell
  text-align: center
  width: 46px
  vertical-align: top
  padding-top: .5rem
  border-left: 2px solid $pp-light-border
  position: absolute
  right: 0
  top: 2px
  height: 40px

  .Select--rtl &
    padding-right: 0

.Select-arrow:before
  font: bold normal normal 1.5rem font-ico
  content: $fa-angle-down

// Animation
// ------------------------------

// fade in

@-webkit-keyframes Select-animation-fadeIn
  from
    opacity: 0

  to
    opacity: 1

@keyframes Select-animation-fadeIn
  from
    opacity: 0

  to
    opacity: 1
