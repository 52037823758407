.pp-tips
  border-radius: 0.2rem
  display: flex
  height: auto
  flex-direction: row
  width: 100%
  margin-bottom: .5rem
  .tips-wrapper
    display: flex
    order: 0
  .close-tips
    text-align: right
    margin-left: auto
    margin-bottom: auto
    i
      padding: .5rem 1rem 0
      color: $pp-white
      cursor: pointer
  &.tip-bookmarks
    background-color: $pp-yellow
  &.tip-mylots
    background-color: $pp-green
  &.tip-filters
    background-color: $pp-filters
  &.tip-bargain
    background-color: $orange-F48D52
  &.tip-info
    background-color: $pp-tip-info
  p
    color: $pp-white
    font-size: 1rem
    margin: 0
    padding: 1rem 0
    line-height: 1.2
    display: flex
    align-items: center
    &:first-of-type
      padding: 1rem
      i
        font-size: 2.5rem
    i
      color: $pp-white!important
      font-size: 3.5rem
