@use "sass:math"
@import variables

//Mixin for 'px' values to 'rem'
//Usage "@include rem('margin',15px 0)" outputs to: "margin: 0.9375rem 0"
=rem($property, $px_values)
  // Convert the baseline into rems
  $baseline_rem: math.div($font-baseline_px, 1rem)

  // Print the first line in pixel values
  //#{$property}: $px_values

  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px_values) == "number"
    #{$property}: math.div($px_values, $baseline_rem)
  @else
    // Create an empty list that we can dump values into
    $rem_values: ()

    @each $value in $px_values
      // If the value is zero, return 0
      @if $value == 0
        $rem_values: append($rem_values, $value)
      @else
        $rem_values: append($rem_values, math.div($value, $baseline_rem))

    // Return the property and its list of converted values
    #{$property}: $rem_values

//White boxes with shadows
=pp-box()
  background-color: var(--bg-color)
  //box-shadow: 0 0 1px rgba(0, 0, 0, 0.1)
  border: 1px solid var(--border-color)

=pp-block()
  background-color: var(--pp-block-bg)
  border: 1px solid var(--pp-block-border)

=feed-block()
  @include pp-box
  min-height: 4rem
  padding: 0 1rem 0 1.5rem
  border-radius: .2rem
  margin-bottom: .6rem
  position: relative
  align-items: center

//wendor prefixes for keyframes
=keyframes($name)
  @-webkit-keyframes #{$name}
    @content
  @-moz-keyframes #{$name}
    @content
  @-ms-keyframes #{$name}
    @content
  @keyframes #{$name}
    @content

=btn-mobile()
  height: $btn-height-mobile!important
  min-width: $btn-min-width-mobile!important
  font-size: $btn-font-size-mobile
  justify-content: center
  i
    font-size: 1.1rem

//Brakepoints mixins
@mixin xs
  @media (max-width: $sm-breakpoint - 1)
    @content

@mixin sm
  @media (min-width: $sm-breakpoint) and (max-width: $md-breakpoint - 1)
    @content

@mixin md
  @media (min-width: $md-breakpoint) and (max-width: $lg-breakpoint - 1)
    @content

@mixin lg
  @media (min-width: $lg-breakpoint) and (max-width: $xl-breakpoint - 1)
    @content

@mixin xl
  @media (min-width: $xl-breakpoint) and (max-width: $xxl-breakpoint - 1)
    @content

@mixin xxl
  @media (min-width: $xxl-breakpoint)
    @content

@mixin sm_height
  @media (max-height: $sm-height)
    @content
