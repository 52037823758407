.slider-background
  background-color: $slider-inactive
  height: .5rem
  border-radius: .2rem
  margin-top: .5rem

.slider-progressbar
  background-color: $pp-yellow
  height: .5rem
  position: absolute
  top: 0
  max-width: 100%

.slider-handle
  width: $slider-size
  height: $slider-size
  border-radius: $slider-size
  top: 40%
  transform: translate3d(-50%,-50%,0)
  background: $slider-inner
  border: $slider-border
  cursor: pointer
  z-index: 1
  box-shadow: 0 1px 3px rgba(0,0,0,.4), 0 -1px 3px rgba(0,0,0,.1)

.slider-input-left,
.slider-input-right
  display: flex
  flex-direction: column
  span
    font-size: .7rem
  input
    border: none
    background: transparent

.slider-input-right
  margin-left: auto
  input,
  span
    text-align: right
