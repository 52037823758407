@import ../base/variables
@import  ../base/mixins

@media print
  *
    -webkit-print-color-adjust: economy !important
    color-adjust: economy !important
  .btn
    display: none
  .pp-content-wrapper
    margin-left: 0!important
  .lot-view-left,
  .single-pp-wrapper
    float: left
    flex: 0
  .single-pp-wrapper
    width: 700px
  .lot-view-right
    float: right
    flex: 0
    padding: 0
    .block
      width: 300px
  .image-gallery-fullscreen-button
    display: none
  .my-avatar.large
    width: 3rem
    height: 3rem
  .recharts-surface
    width: 280px
  .image-gallery-thumbnails
    .image-gallery-thumbnails-container
      max-width: 60vw
      overflow: hidden
  @page
    size: A4
  .streetad
    &__img
      max-height: 300px
      text-align: center
      box-sizing: border-box
      &.h400px
        max-height: 400px
        img
          height: 400px
      img
        height: 300px
    &__desc,
    &__filters
      margin: 1rem 1.5rem
      font-weight: bold
      font-size: 1.5rem
      width: calc(100% - 3rem)
      overflow: hidden
      .single-pp-data-key
        font-size: 1rem!important
    &__desc
      height: 25px
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      line-height: 1
    //&__filters
      height: 376px
      margin-top: -.5rem
      overflow: hidden
      border-bottom: 1px solid $pp-gray-border
    &__qrcode
      margin: -.5rem 1.5rem 1rem
      width: calc(100% - 3rem)
      height: 260px
      overflow: hidden
      border-bottom: 1px solid $pp-gray-border
    &__title
      h1,
      h2
        text-align: center
        margin-top: 1.5rem
        font-size: 3.5rem
    &__contacts
      display: flex
      position: absolute
      bottom: 0
      left: 20px
      right: 20px
      height: 230px
    &__phone
      border-right: 2px dashed $pp-gray-border
      padding: 2rem 2rem 6rem
      font-size: 1.5rem
      width: 100px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-end
      position: relative
      &:last-of-type
        border: none
      div
        transform: rotate(-90deg)
        position: absolute
        bottom: 150px
        span
          display: block
          white-space: nowrap
          font-size: 1.2rem
      canvas
        position: absolute
        bottom: 20px
