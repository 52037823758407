.btn
  white-space: nowrap
  &:focus,
  &.focus
    outline: none
  &.disabled,
  &:disabled
    cursor: no-drop!important
  &:active,
  &.active
    background-image: none
  &:hover
    cursor: pointer
  i
    color: $pp-white
    padding-right: 0.4rem
    font-size: 0.85rem
  &.is-on
    border: 1px solid var(--btn-is-on-border)
    background: var(--btn-is-on-bg)
    color: var(--bs-btn-color)
    i
      &.pp-heart,
      &.pp-bar-chart
        color: $pp-yellow
  i
    +rem("line-height", 13px)
  .pp-pp
    font-size: 1rem
  span
    display: inline-flex
    min-width: 1rem
  &__detailed-view
    position: absolute

.btn-primary
  i
    color: var(--btn-primary-icon-color)

.icon
  background: none
  color: white
  border: none
  font-size: 2rem

.btn-position-right
  float: right
  button
    min-width: 7rem
    margin-left: 1rem

.btn-action
  +rem('width', 32px)
  +rem('height', 32px)
  padding: .5rem
  min-width: auto
  display: inline-flex
  align-items: center
  span
    display: none
  i
    color: var(--inverted-link-color)
  &.btn-back
    width: inherit
    font-weight: normal
    +rem('font-size', 12px)
  &.btn-sort
    width: auto
    font-weight: normal
    text-align: left
    span
      display: inline-block

.btn-inline
  .btn-secondary
    margin-right: 0.5rem

.share-lg
  +rem("font-size", 18px)
  .btn-action
    align-items: center
    height: 3rem
    justify-content: center
    display: inline-flex
    &.soc-fb
      i
        color: $social-facebook
    &.soc-x
      i
        color: $social-x
    &.soc-wa
      i
        color: $social-whatsapp
    &.soc-ln
      i
        color: $social-linkedin
    &.soc-pn
      i
        color: $social-pinterest
    &.soc-dr
      i
        color: $social-draugiem
    i
      font-size: 2rem
    span
      display: inline-block
      font-weight: bold
      color: $pp-gray
      +rem("font-size", 14px)
      margin-left: .5rem
      &.social-ico
        margin: 0

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset[disabled] a.btn
  pointer-events: none

.btn-secondary
  font-weight: normal
  i
    color: $btn-secondary-icon
  &:focus
    color: initial

.btn-default
  background-color: rgba($pp-black, 0.3)
  border: 2px solid $pp-white
  color: $pp-white
  width: 100%
  &:hover
    background-color: rgba($pp-black, 0.5)
    color: $pp-white
  &:active
    background-color: $pp-white
    color: $pp-black
  i
    color: $pp-yellow

.btn-sm
  height: 2rem
  align-items: center
  +rem("font-size", 12px)
  padding: .5rem
  display: inline-flex

a.btn-icon
  i
    color: $pp-gray
  &:hover
    i
      color: $pp-black !important

.btn-block
  display: block
  width: 100%

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"]
  &.btn-block
    width: 100%

button
  cursor: pointer
  &:focus
    outline: 0

.back-btn
  cursor: pointer
  span
    text-transform: uppercase
    +rem("font-size", 12px)
  i
    color: $pp-yellow
    padding: .4rem .4rem .4rem .8rem
    font-size: 1rem
    cursor: pointer

.btn-icon
  cursor: pointer

.filter-btn
  padding: 0
  margin: 0
  .form-check-label
    padding: .2rem .3rem
    display: flex
    align-items: center
    margin-right: .3rem
    border-radius: .2rem
    cursor: pointer
    &:before,
    &:after
      display: none
    span
      position: relative
      z-index: 1
  .form-check-input:checked
    ~ .form-check-label
      background-color: $pp-yellow

.btn-transparent
  background-color: transparent
  color: $pp-white
  font-weight: bold
  &:hover
    background-color: rgba($pp-hover-overlay, 0.7)
    color: $pp-white
  &:active
    i
      color: $pp-black
  i
    color: $pp-white

.btn-edit
  border: 1px solid $pp-btn-secondary-border
  position: absolute
  right: .25rem
  top: .25rem
  padding: 0
  height: 2rem
  width: 2rem
  z-index: 5
  background: $pp-white
  i
    color: $pp-gray
    padding: 0
  &:hover
    background-color: $pp-yellow
    border-color: $pp-yellow
    i
      color: $pp-white

.btn-add-more
  color: $pp-gray
  padding: 0
  height: auto
  i
    color: $pp-gray
  &:focus
    box-shadow: none
  &:hover
    i
      color: var(--btn-secondary-hover-text)

.btn-remove
  padding: 0
  width: auto!important
  margin-top: 1.5rem
  &:focus
    box-shadow: none
  i
    color: $pp-yellow
    font-size: 1.5rem

.two-buttons-l
  margin-bottom: 1rem
  display: flex
  .btn
    width: 50%
    margin-left: .25rem
    &:first-of-type
      margin-left: 0

.two-buttons-s
  display: flex
  .btn
    margin-left: .1rem
    &:first-of-type
      margin-right: .1rem
      margin-left: 0

.btn-only-icon
  background: transparent
  border: none
  color: $pp-gray
  &:hover
    color: var(--icon-color-hover)
    background: transparent
    .pp-heart-o,
    .pp-bar-chart
      color: $pp-bookmarks
    i
      color: var(--icon-color-hover)
  &:focus,
  &:active
    outline: -webkit-focus-ring-color auto 1px
    background: transparent!important
  &.is-on
    .pp-heart,
    .pp-bar-chart
      color: $pp-bookmarks

.btn-loan
  background-color: $green-EDF8F6
  border: 1px solid $green-4ABDAC
  margin-left: .25rem
  margin-bottom: .25rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: auto
  padding: 0
  i
    color: $green-4ABDAC
    padding: 0
    font-size: 2rem
    line-height: inherit
    margin-bottom: .25rem
  span
    white-space: normal
  &:hover,
  &:active,
  &:focus
    background-color: darken($green-EDF8F6, 10%) !important
    border-color: $green-4ABDAC !important
  &:active,
  &:focus
    box-shadow: 0 0 0 0.2rem rgba(darken($green-EDF8F6, 10%), 0.9) !important

.btn-hover-style:hover
  .btn-sort
    background-color: var(--bs-btn-hover-bg)
    border: 1px solid var(--bs-btn-hover-border-color)
    color: var(--bs-btn-active-color)
    i
      color: var(--bs-btn-active-color)

.btn-inactive
  pointer-events: none

.action-buttons-container
  position: fixed
  right: 1rem
  bottom: 0
  z-index: $zindex-scroll-to-top // Ensure it's above other elements

  .scroll-to-top
    background-color: $pp-yellow
    cursor: pointer
    border-radius: 50%
    width: 4rem
    height: 4rem
    border: 5px solid darken($pp-yellow, 10%)
    outline: none
    z-index: inherit
    transition: all 0.3s ease
    opacity: 0.8
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 1rem // Space between the buttons
    i
      color: $pp-white
      font-size: 2rem
    &:hover
      opacity: 1
      transform: scale(1.25)

.btn-telegram
  display: inline-flex
  justify-content: center
  align-items: center
  white-space: normal
  cursor: pointer
  text-transform: uppercase
  font-size: 12px
  font-weight: normal
  i
    margin-right: 10px
  &:hover
    span
      text-decoration: underline

.btn-subscribe
  background-color: $green-88BC2A
  justify-content: center
  align-items: center

.user-feedback
  background-color: $pp-yellow
  right: 0
  bottom: 40rem
  position: fixed
  cursor: pointer
  border-radius: 50%
  width: 4rem
  height: 4rem
  border: 5px solid darken($pp-yellow, 10%)
  outline: none
  z-index: 400
  transition: all 0.3s ease
  opacity: 0.8
  .emoji
    line-height: 2
    margin-right: .5rem
    color: $pp-yellow
    opacity: 0.5
    &:hover
      opacity: 1
  i
    color: $pp-white
    font-size: 1.5rem
    left: 15px
    position: relative
  .pp-ico
    line-height: 2
  &:hover
    opacity: 1

.share-feedback
  border-radius: 0
  border-top: none
  border-left: none
  border-right: 0
  padding: 1.5rem 1rem
  font-weight: 600
  i
    font-size: 1.5rem
    color: $pp-yellow
    padding-right: .5rem
  &:active
    border-bottom: 1px solid var(--bs-btn-border-color) !important

.btn-text
  color: $red
  border: none
  background: none

.btn-long-text
  white-space: wrap
  display: flex
  align-items: center
  justify-content: center
