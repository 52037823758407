.range-slider
  width: 100%
  height: .75rem
  -webkit-appearance: none
  background: $gray-D8D8D8
  outline: none
  border-radius: .75rem
  overflow: auto
  cursor: pointer
  background-image: linear-gradient($pp-yellow, $pp-yellow)
  background-repeat: no-repeat
  &::-webkit-slider-thumb
    -webkit-appearance: none
    width: .75rem
    height: .75rem
    border-radius: 50%
    background: $pp-blue
    cursor: ew-resize
    border: 2px solid $pp-blue
    box-shadow: -807px 0 0 800px $pp-yellow
  &::-moz-range-thumb,
  &::-ms-thumb
    -webkit-appearance: none
    height: 20px
    width: 20px
    border-radius: 50%
    background: $pp-blue
    cursor: ew-resize
    transition: background .3s ease-in-out

.range-min-max
  justify-content: space-between
  display: flex
  top: -5px
  position: relative
  span
    color: $pp-gray
    font-size: .75rem

.loan-calculated
  font-size: 1.5rem
  line-height: 1
  text-align: center
  color: $pp-blue
  span
    font-size: 1.2rem

.pp-leasing
  width: 7rem
  height: 3rem

.position-top
  position: absolute
  left: 1rem
  top: 1rem
  .collaboration
    position: relative
    top: -0.8rem
    left: 3rem

.loan-info
  color: $pp-gray
  font-size: .85rem
  list-style: none
  padding: 0
  margin: 0
  text-align: left
  line-height: 1.2
  li
    display: flex
    align-items: center
    line-height: 1
    &:before
      font-size: 1.5rem
      content: "\2022"
      color: $pp-yellow
      display: flex
      vertical-align: baseline
      align-items: center
      min-width: 1rem
      margin-top: -0.2rem

.loan-lot
  border-top: 1px solid $pp-gray-border
  padding: 1rem 2rem
  background-color: $pp-blue
  position: relative
  margin-bottom: 1rem
  .fieldset-label
    margin-bottom: 0
  select,
  .view-offer
    width: 12.5rem
  &__centered
    display: flex
    align-items: center
  .pp-leasing
    margin-right: auto
  .loan-calculated
    color: $pp-white
    white-space: nowrap
    margin-right: 1rem
  .loan-info
    color: $pp-white
    margin-bottom: 1rem
  .collaboration
    right: .1rem
    width: 6rem
    color: $pp-white
    top: 0.5rem
    +rem("max-width", 86px)

.short-loan
  font-size: .95rem
  color: $pp-white
  font-weight: 500
  position: relative
  top: 0.15rem
  text-align: right
  padding-right: .5rem
  div
    padding: .25rem .15rem .15rem .15rem
    background: $pp-blue
    border-radius: 0.15rem
    display: inline-flex
    align-items: baseline
    line-height: 1
  .per-month
    font-size: .75rem
    margin-left: .15rem

.leasing-form
  padding: 0 1rem
  .leasing-check
    .form-check
      label
        font-weight: bold
        font-size: .85rem
        color: $pp-brown
  .collaboration
    position: static
  [class^="col-"],
  [class*=" col-"]
    display: flex
    .form-group
      width: 100%
      margin-top: auto

.loan-block
  border: 4px solid $pp-blue
  margin-top: 1.3rem
  position: relative
  padding-top: 1.5rem
  .pp-leasing
    position: absolute
    right: 0
    left: 0
    margin-left: 1rem
    margin-right: auto
    top: -1.5rem
    padding-right: 0.25rem

.top-n
  .short-loan
    top: -.5rem

.collaboration
  font-size: .7rem
  text-transform: uppercase
  position: absolute
  right: 0
  top: 0
  margin-left: auto
  margin-right: auto
  text-align: center
  img
    +rem("width", 50px)

.loan-provider
  +rem("width", 200px)
  margin-right: auto
  position: relative

.second-step
  svg
    background-color: $pp-white
  .collaboration
    background: $pp-white
    padding: 1rem
    top: 0.25rem
    position: relative

fieldset
  &.second-step
    margin-top: -1.5rem
    padding-top: 1.5rem

//Autego leasing
.autego
  border: 1px solid $pp-gray-border
  background: transparent linear-gradient(177deg, #FFE34A 0%, #FFBA1F 100%) 0 0 no-repeat padding-box
  opacity: 1
  position: relative
  overflow: hidden
  &__wrapper
    padding: 2rem 2rem 1rem 2rem
    background-image: url("../img/autego/wave.png")
    background-repeat: no-repeat
    background-size: 100%
    background-position: 0 -160px
    color: #212529
  h2
    font: normal normal 700 26px/30px system-ui
    padding-right: 1rem
    margin-bottom: 1.5rem
  &__logo
    width: 130px
    height: 39px
    margin-left: auto
  &__intro
    display: flex
    p
      font: normal normal 600 18px/20px system-ui
      min-width: 220px
      min-height: 21px
      span
        color: $autego-red
        font: normal normal 600 17px / 20px system-ui
  .autego__anim__text
    ul
      padding: 0
      margin-bottom: 1rem
      list-style: none
      display: flex
      li
        background: black
        width: 24px
        height: 24px
        color: white
        display: flex
        justify-content: center
        align-items: center
        border-radius: 50%
        font: normal normal bold 12px/18px system-ui
        margin-right: 1.5rem
        transition: ease-in-out 0.25s
        &:after
          content: ""
          border-bottom: 1px solid black
          width: 24px
          margin-left: 3rem
          position: absolute
        &:last-child
          &:after
            border: none
        &.active
          background: $autego-red
          &:after
            border-color: $autego-red
  &__anim
    &__text
      p
        display: none
        font-weight: 400
        &.active
          display: block
    &__img
      margin-left: auto
      div
        display: none
        width: 140px
        height: 140px
        background-size: contain
        background-repeat: no-repeat
        &.active
          display: block
  &__center
    margin-top: 1rem
    div
      flex: 1
      align-items: center
    &__starting
      font: normal normal bold 22px/24px system-ui
      color: $autego-red
      p
        color: $pp-black
        white-space: nowrap
        margin: 0
      span
        white-space: nowrap
    &__monthly
      background: $autego-red 0 0 no-repeat padding-box
      font: normal normal bold 25px/25px system-ui
      color: $autego-white
      margin: 0 0 0 1rem
      padding: 1rem
      border-radius: 34px
      display: flex
      justify-content: center
      align-items: center
      height: 4rem
      white-space: nowrap
      position: relative
      i
        font-size: 1.2rem
        position: absolute
        left: -0.5rem
      span
        font: normal normal bold 26px/26px system-ui
    &__form
      input
        width: 100%
        border-radius: 10px
        padding: 0.5rem 1rem
        border: 1px solid $autego-yellow
        &.form-control
          height: 2.5rem
          font-weight: normal
          color: var(--form-input-color)
        &::placeholder
          color:  $autego-input-placeholder
          font-style: normal
      .react-tel-input
        .form-control
          padding-left: 2.5rem
      .grid
        row-gap: 0
        column-gap: 0.25rem
  &__checkbox
    input
      width: auto
      padding: 0.5rem
      &:checked
        background: $pp-yellow
        border: white
        background-size: contain
        background-image: $autego-checkbox!important
    label
      font: normal normal 200 12px/14px system-ui!important
  &__btn
    background: transparent linear-gradient(184deg, $autego-red 20%, $autego-yellow 150%) 0 0 no-repeat padding-box
    border-radius: 10px
    width: 100%
    border: none
    color: white
    box-shadow: 0 8px 20px 0 rgba(0,0,0,0.5)
    height: 3rem
    margin-top: 1rem
    font: normal normal 600 17px/19px system-ui
    user-select: none
    i
      padding-left: .5rem
    &:hover
      background: transparent linear-gradient(184deg, $autego-red 50%, $autego-yellow 100%) 0 0 no-repeat padding-box
    &:active
      box-shadow: none
  .react-tel-input
    .flag-dropdown
      background: none
      border: none
      &.open
        .selected-flag
          background: none
      .selected-flag:hover
        background: none
      .search
        display: flex
        align-items: center
  &__info
    text-align: center
    font: normal normal 200 12px/15px system-ui
    margin: 1rem 0 0
