.input-select
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-end
  width: 100%
  background-color: var(--form-input-bg)
  input
    position: relative
    z-index: 1
    width: calc(100% - 2rem)
    background: transparent
    border-color: transparent
    border-right: none!important
    transition: none
    height: auto
    &:focus
      box-shadow: none
      border-color: $pp-yellow
      border-right: none
      -webkit-appearance: none
  select
    position: absolute
    bottom: 0
    right: 0
    z-index: 0
    color: $pp-white
    background-color: transparent
    &:focus
      border-color: $pp-yellow
      color: $pp-white
    option
      color: var(--form-input-color)


form .form-control.disabled-input
  background-color: #f5f5f5 !important
  color: #6c757d !important
  border: 1px solid #ced4da !important
  pointer-events: none
