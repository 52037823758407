.swiper
  margin-left: auto
  margin-right: auto
  position: relative
  overflow: hidden
  list-style: none
  padding: 0.25rem
  z-index: 1
  display: block
  .masonry-block-width
    width: auto
  .swiper-nav
    top: -2rem
    position: absolute
    z-index: 1
    font-size: 2rem
    width: 5rem
    height: 5rem
    background: $pp-yellow
    border-radius: 50%
    color: $pp-white
    display: flex
    visibility: hidden
    opacity: 0
    transition: visibility 0s, opacity 0.5s linear
    cursor: pointer
    align-items: end
    padding: 0.75rem
    border: 1px solid $pp-yellow
    &.pp-angle-left
      left: -2rem
      justify-content: end
    &.pp-angle-right
      right: -2rem
  &:hover
    .swiper-nav
      visibility: visible
      opacity: 1

.swiper-wrapper
  position: relative
  width: 100%
  height: 100%
  z-index: 1
  display: flex
  transition-property: transform
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial)
  box-sizing: content-box

.swiper-horizontal
  touch-action: pan-y

.swiper-vertical
  touch-action: pan-x

.swiper-slide, swiper-slide
  flex-shrink: 0
  width: 100%
  height: 100%
  position: relative
  transition-property: transform
  display: block

.swiper-slide-invisible-blank
  visibility: hidden

.swiper-autoheight
  height: auto

  .swiper-slide
    height: auto

  .swiper-wrapper
    align-items: flex-start
    transition-property: transform,height

.swiper-backface-hidden .swiper-slide
  transform: translateZ(0)
  -webkit-backface-visibility: hidden
  backface-visibility: hidden

.swiper-pagination
  position: absolute
  left: 0
  right: 0
  bottom: .5rem
  text-align: center
  font-size: .85rem
  color: $pp-gray
