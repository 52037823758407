.profile-image-cropper
  position: relative
  span
    font-size: 1.8rem
    cursor: pointer
    margin-top: .1rem
    color: $pp-gray
    opacity: .8
    &:hover
      opacity: 1
  button[type="submit"]
    background: none
    border: 0
    color: #828081
    font-size: 1.8rem
    opacity: 0.8
    border-radius: 0
    margin: 0
    padding: 0
    width: 100%
    height: 100%
    text-align: left
  .default-avatar
    width: 2.2rem
    height: 2.2rem
    text-align: center
    padding: 0.3rem
    font-size: 1.5rem!important
    text-decoration: none

.default-avatar
  width: 3rem
  height: 3rem
  background-color: $avatar-bg
  border-radius: 50%
  color: $pp-white
  padding: 0.3rem 0.5rem
  font-size: 2rem

.social-avatar
  display: flex

.my-avatar
  display: inline-block
  width: 2.2rem
  height: 2.2rem
  background-size: cover
  background-position: top center
  background-color: var(--pp-bg-gray)
  border-radius: 50%
  &.large
    width: 3rem
    height: 3rem
    min-width: 3rem

img.my-avatar
  object-fit: cover
