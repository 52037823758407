.footer-wrapper
  padding: 1rem
  +rem("font-size", 14px)
  background-color: var(--footer-bg)
  font-weight: 400
  min-height: $footer-height
  margin-top: .5rem
  color: var(--masonry-text)
  div
    a:hover
      text-decoration: underline
    a
      cursor: pointer
      line-height: 1
      color: var(--masonry-text)
      text-decoration: none
      div
        text-decoration: underline
    i
      cursor: pointer
      padding: 0 10px 0 0
      font-size: 1rem
      width: 1.625rem
    //div > span:first-child
      white-space: nowrap
  .powered-by-inbox
    .txt
      fill: var(--inverted-text-color)!important
