.pagination
  justify-content: center
  margin-top: 3rem
  .input-paging
    margin-right: 0.2rem
    .input-field
      @include rem("font-size", 14px)
      max-width: 2.26rem
      height: 2rem
      text-decoration: none
      padding: 0.5rem 0
      cursor: pointer
      text-transform: none
      letter-spacing: normal
      box-sizing: border-box
      display: flex
      align-items: center
      outline: none
      &:focus
        border: 1px solid $pp-btn-secondary-border
        box-shadow: none
      &:hover
        border: 1px solid $pp-btn-secondary-border
  .page-item
    a, span
      display: flex
      align-items: center
      border-radius: $pagination-border-radius
    &.active
      a
        font-weight: bold
        box-shadow: inset 0 0 0 1px $pp-yellow
  .page-link
    +rem("font-size", 14px)
    min-width: 2rem
    height: 2rem
    text-decoration: none
    cursor: pointer
    text-transform: none
    letter-spacing: normal
    margin: 0 .2rem 0 0
    color: var(--masonry-text)
    box-sizing: border-box
    &.btn-primary
      display: flex
      min-width: 7rem
      justify-content: center
      padding: 0 .75rem
      background-color: $btn-primary-bg
      color: $pp-black
      border: none
      &:hover
        background-color: darken($btn-primary-bg, 10%)
      i
        width: 1rem
  i
    padding: 0
    font-size: 1.2rem

