.modal-dialog
  height: 100%
  form
    width: 100%

.modal-content
  height: 100%
  overflow-y: auto // TODO: CLASF-644 overflow-y: scroll ?

.modal-header
  justify-content: flex-end

.modal-dialog.prompt
  max-width: 39rem
  margin: 1rem auto
  overflow-y: auto
  height: auto
  &.modal-category-mapping
    max-width: 70rem
  &.modal-create
    flex-direction: column
    .modal-header
      flex-direction: column
      .modal-title
        margin-bottom: 1rem
        display: flex
        width: 100%
        padding-top: .3rem
        text-transform: uppercase
        letter-spacing: 1.5px
      i
        font-size: 1.3rem
  .modal-content
    height: initial
    overflow-y: auto
    //background-color: $pp-white
    //border: 1px solid $pp-gray-border
    //color: $pp-black
  .modal-header
    padding: 1rem 0 0 1rem
    justify-content: flex-start
    i
      margin-right: .5rem
      color: $pp-yellow
      &.pp-exclamation-triangle
        color: $pp-orange
      &.pp-angle-right
        font-size: 2rem
        font-weight: bold
    h6
      font-size: 1.125rem
      font-weight: bold
      i
        position: static
        color: $pp-yellow
  .modal-title
    +rem('font-size', 18px)
    +rem('line-height', 22px)
    font-weight: bold
    width: calc(100% - 1rem)
  .modal-body
    font-size: 1rem
  &.modal-statistics
    .modal-title
      width: 100%
      font-weight: normal
      .modal-close
        float: right
    .no-data-msg
      +rem("min-height", 300px)
      display: flex
      justify-content: center
      align-items: center
      margin: 0
    .custom-radio
      margin-right: 1rem
  .change-role
    span
      margin-left: 1rem
      font-weight: bold
  button.pp-close-thin
    color: $pp-gray!important
    margin-top: -.5rem
    margin-right: 0.5rem
    padding: 0
    z-index: 99999
  &.modal-loan
    .modal-content
      background-color: $pp-blue
      color: $pp-white
    .pp-close-thin
      color: $pp-white
    .published-page
      p
        color: $pp-white
    .pp-leasing
      position: relative
      top: -1rem
      left: 1rem
    .position-top
      left: 0
      top: 2rem
      .collaboration
        left: 1rem
    h4
      text-transform: none
      margin-top: 1rem

.modal-dialog
  .pp-close-thin
    background: none
    color: white
    border: none
  button.pp-close-thin
    font-size: 2rem
  &.modal-subscribe,
  &.modal-gdpr
    height: auto
    position: fixed
    bottom: 0
    z-index: $zindex-gdpr!important
    .modal-body
      padding-top: .5rem
      a
        color: $pp-white
    .modal-content
      .modal-info
        font-size: 1.2rem
        font-weight: 100
      button, i
        &.pp-close-thin
          font-size: 2rem
          position: absolute
          right: 0
    .form-check-label
      font-size: 1rem

.modal-new-message
  height: auto
  position: fixed
  top: 49%
  right: 1rem
  cursor: pointer
  .modal-close
    float: right

.social-share-modal
  h6
    font-weight: 100
    font-size: 1rem
    +rem('margin', 15px 0)
  .row
    margin-bottom: 0.1rem

a.language
  color: $pp-white !important
  +rem("font-size", 12px)
  text-transform: uppercase
  cursor: pointer
  display: flex
  align-items: center
  text-decoration: none
  span
    margin-right: .5rem
    display: inline-block
    width: 1rem
    height: 0.75rem

.colleague-settings
  .colleague-avatar
    margin: 1rem 0 2rem
    text-align: center
    .default-avatar
      font-size: 1.5rem
      width: 2.5rem
      height: 2.5rem
      padding: 0.5rem
      margin-right: .5rem
    span
      font-weight: bold
  .colleague-role
    text-align: center
    .form-check:first-of-type
      margin-right: 4rem
  hr
    border-width: 1px
  .colleague-permissions
    margin: .5rem 0 2rem
    h6
      font-weight: bold
      text-transform: uppercase
      font-size: 0.75rem
      letter-spacing: 1.33px
      margin-bottom: 1rem
      i
        color: $pp-yellow
        font-size: initial
        font-weight: initial
        padding-right: .3rem

.modal-adult-content
  filter: blur(1rem)
  overflow: hidden

.adult-icon
  position: absolute
  font-size: 5rem
  z-index: 1
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  color: $pp-white

.modal-btn-group
  display: flex
  .btn
    &:first-of-type
      margin-right: .5rem
    &:nth-child(2)
      margin-left: .5rem

.modal-company-logo
  max-height: 6rem
  max-width: 6rem
.modal-subscribe-backdrop
  height: auto !important
.modal-subscribe-open
  overflow-y: scroll!important

.modal-subscribe
  width: 25rem
  right: 0
  .modal-content
    background-color: var(--bs-body-bg)
    color: var(--inverted-text-color)
    border-radius: 5rem 0 0 0
    box-shadow: 0 0 4px 2px rgba($pp-black, 0.1)
    .modal-title
      button
        top: .5rem
        color: $pp-gray
    h1
      text-align: center
      line-height: 1
      font-size: 1.7rem
      display: flex
      padding: 0 2rem
  .subscribe-icon
    background: $pp-yellow
    width: 3rem
    height: 3rem
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    flex-shrink: 0
    margin-right: .5rem
    i
      font-size: 2rem
      color: $pp-white

.modal-scammer
  h5
    font-size: 1.125rem
    margin-top: 1.5rem
    text-transform: initial
    font-weight: normal
    text-align: center
  p
    font-size: 0.875rem
    margin-bottom: .5rem!important
    margin-top: .5rem!important
  strong
    white-space: pre-line

.modal-pro
  .btn
    margin-top: 1rem

.modal-gdpr
  width: 100%
  .modal-content
    background-color: var(--bs-modal-bg)
    color: $pp-white
    flex: 1
    .modal-title
      text-transform: none
      font-weight: normal
      font-size: 2rem
  .modal-body
    display: flex
    align-items: center
  h5
    font-size: 1.125rem
    text-transform: initial
    margin-bottom: 0.25rem
  p
    margin-bottom: .25rem
    font-weight: 100

.modal-gdpr-show
  height: auto !important
  overflow-y: scroll!important
  .modal.show
    height: 0

.discount
  &__preview
    height: 12rem
    width: 25%
    margin: 0 .25rem
    justify-content: unset
    overflow: visible
    &__span
      height: 1rem
      width: 40%
      margin: .25rem
      background: var(--skeleton-bg)
      border-radius: $border-radius
    &__date
      position: absolute
      bottom: -1rem
      white-space: nowrap
      font-size: .75rem
      color: $pp-gray
  &__form
    margin-top: 4rem
    margin-bottom: 1rem
    padding: 0 10%

.modal-create-shipment.prompt
  overflow: visible
  .modal-content
    overflow: visible
    .custom__select
      max-height: 50vh

.parcel-tracking
  list-style: none
  margin-top: 2rem
  color: $pp-icon
  li
    display: flex
    align-items: center
    padding-bottom: 2rem
    position: relative
    opacity: .8
    &:last-of-type
      &:after
        border: none
    &:after
      content: ""
      border-left: 1px dashed $pp-icon
      height: 2rem
      position: absolute
      left: 1rem
      top: 2rem
    i
      font-size: 2rem
      margin-right: .75rem
    &.processed
      color: var(--inverted-text-color)
      &:after
        border-color: $pp-green
      i
        color: $pp-green
  div
    line-height: 1
    p
      margin-bottom: 0

.hints-container
  display: flex
  justify-content: center
  align-items: center
  margin: 1rem 0
  .hints-image
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 1.5rem
    width: 100%
    max-width: 500px
    span
      display: flex
      position: relative
      img
        display: block
        width: 100%
        border-radius: 0.5rem
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
      i
        background-color: #b83030
        border-radius: 50%
        position: absolute
        bottom: 10px
        right: 10px
        color: #fff
        display: flex
        justify-content: center
        align-items: center
        font-size: 16px
        height: 40px
        width: 40px
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
    .good
      i
        background-color: #24977b

.hints-text
  text-align: center
  margin-top: 1rem
  h3
    font-size: 1.5rem
    font-weight: 600
    color: var(--inverted-text-color)
    margin-bottom: 0.5rem
  p
    font-size: 1rem
    line-height: 1.6
    color: var(--masonry-text)
    margin: 0

.modal-parcel-machine.prompt
  max-width: var(--bs-breakpoint-lg)

.delivery
  background-size: contain
  background-repeat: no-repeat
  height: 2.5rem
  width: 6rem
  background-position: 0 0
  &.omniva
    background-image: url(../img/delivery/omniva.png)
  &__option
    border: 1px solid var(--border-color)
    padding: 1rem
    width: 100%
    .invalid-feedback
      display: none
    .delivery
      height: 3rem
      background-size: contain
      width: 7rem

.modal-seller-card
  bottom: 0
  right: 0
  left: 0
  position: absolute
  .seller-info
    max-height: 80vh
