.card-view-all
  border-top: solid $pp-gray-border 1px
  bottom: 0
  cursor: pointer
  height: 36px
  padding: .5rem 1rem
  div
    color: grey
    text-transform: uppercase
    +rem("font-size", 12px)
    font-weight: 100
  .pp-ico
    float: right
    padding: 0
    display: inline-block
    text-decoration: none
    font-size: 1rem
    color: grey
  .pp-ico
    padding-right: 0.4rem
    font-size: 1rem

.card.pp-block
  .pp-ico.parent-icon
    font-size: 2.5rem
    padding: 0.5rem 0
    color: $pp-orange

.card-title.parent-name
  word-break: break-word
  cursor: pointer
  text-transform: uppercase
  line-height: 1.2
  margin-bottom: .5rem
  font-weight: 600
  +rem("font-size", 18px)
  letter-spacing: 2px

.card-view-all
  border-top: solid $pp-gray-border 1px
  bottom: 0
  cursor: pointer
  height: 36px
  padding: .5rem 1rem
  div
    color: grey
    text-transform: uppercase
    +rem("font-size", 12px)
    font-weight: 100
  .pp-ico
    float: right
    padding: 0
    display: inline-block
    text-decoration: none
    font-size: 1rem
    color: grey
  .pp-ico
    padding-right: 0.4rem
    font-size: 1rem

.parent-block.pp-block
  .pp-ico.parent-icon
    font-size: 2.5rem
    padding: 0.5rem 0
    color: $pp-orange

.empty-card
  display: inline-block
  width: 100%
  height: 4rem

.category-link
  display: block
