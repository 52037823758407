@use 'sass:math' as math
//Redesigned/refactored color scheme
$category-icon-gray:                    #ACACAC
$category-border-gray:                  #e8e8e8
$category-background-gray:              #FAFAFA
$category-select-bg:                    #FAFAFA
$cat-transport:                         #94bcf3
$cat-realEstate:                        #83cb79
$cat-clothes:                           #9f8ed8
$cat-services:                          #eeaa64
$cat-health:                            #ee7d73
$cat-animals:                           #bda76d
$cat-garden:                            #7da45d
$cat-electronic:                        #869cd2
$cat-construction:                      #acacac
$cat-homeStuff:                         #cf6567
$cat-food:                              #74b9c8
$cat-agriculture:                       #9ab94e
$cat-production:                        #658fcf
$cat-kids:                              #ff9981
$cat-hobby:                             #77ce5f
$cat-charity:                           #6abfac
$cat-adultContent:                      #df89f8
$cat-discount:                          #BF2827
$categories:                            transport, realEstate, clothes, services, health, animals, garden, electronic, construction, homeStuff, food, agriculture, production, kids, hobby, charity, adultContent, discount
$text-gray:                             #667284
$icon-gray:                             #898989
$icon-color-hover:                      #3e3e3e

$white-FFFFFF:                          #FFFFFF
$blue-295e7e:                           #295e7e

$bg-color:                              #FAFAFA
$bg-white:                              #FFFFFF
$border-color:                          #EEEEEE
$lot-bg-color:                          #FFFFFF
$pp-bg:                                 #FFFFFF
$breadcrumb-bg:                         $bg-color
$breadcrumb-border:                     $border-color
$btn-secondary-bg:                      $bg-color
$features-text-color:                   $text-gray
$features-gradient:                     linear-gradient(90deg, rgba(115,159,239,1) 0%, rgba(176,210,90,1) 26%, rgba(226,221,90,1) 58%, rgba(228,155,76,1) 100%)
$breadcrumb-text:                       #434343
$avatar-bg:                             #76abcb
$brand-color:                           #31669b
$header-bg:                             $brand-color
$header-search-bg:                      $white-FFFFFF
$header-icons:                          $white-FFFFFF
$link-light:                            #dfe7f0
$link-black:                            #000000
$image-placeholder-bg:                  #bbc7d4
$filter-value-bg:                       #eeeeee
$filter-value-border:                   #eeeeee
$lot-border-color-hover:                #cccccc
$collapse-bg:                           #f4f4f4
$prefered-input:                        #d4ffe8
$feedback-bg:                           #fd4a19
$pp-logo:                               #5b85b0
$static-header:                         #7296b9
$static-text:                           #686868
$static-gradient:                       #f0f0f0
$static-border:                         #FAFAFA
$recommended-border:                    #8dc1ff
$recommended-gradient:                  #418BE0
$recommended-shadow:                    #78BBFD


//$masonry-image-height:                  11.5rem
$category-menu-h-font-size:             .9rem
$category-menu-h-max-width:             11rem

//All color variables ($color_name-hex_value)
$gray-EAECEF:                           #EAECEF
$gray-F3F3F3:                           #F3F3F3
$gray-F6F6F6:                           #F6F6F6
$gray-F8F9FA:                           #F8F9FA
$gray-ECECEC:                           #ECECEC
$gray-E8E8E8:                           #E8E8E8
$gray-EDEDED:                           #EDEDED
$gray-D8D8D8:                           #D8D8D8
$gray-C3D2D8:                           #C3D2D8
$gray-9B9B9B:                           #9B9B9B
$gray-A5B5BD:                           #A5B5BD
$gray-CCCCCC:                           #CCCCCC
$gray-CBCBCB:                           #CBCBCB
$gray-DCD9D7:                           #DCD9D7
$gray-BABABA:                           #BABABA
$gray-828081:                           #828081
$gray-A8A8A8:                           #A8A8A8
$gray-EEEEEE:                           #EEEEEE
$gray-A7A6A6:                           #A7A6A6
$gray-6f6f6f:                           #6f6f6f
$gray-4A4A4A:                           #4A4A4A
$gray-7F7F7F:                           #7F7F7F
$gray-DBDBDB:                           #DBDBDB
$gray-DEDEDE:                           #DEDEDE
$blue-CFD9E2:                           #CFD9E2
$blue-DDE4EA:                           #DDE4EA
$blue-AEC1D0:                           #AEC1D0
$blue-9AB4C1:                           #9AB4C1
$blue-92B1CA:                           #92B1CA
$blue-6197B6:                           #6197B6
$blue-2C90E9:                           #2C90E9
$blue-007BFF:                           #007BFF
$blue-2d90e8:                           #2d90e8
$blue-37759A:                           #37759A
$blue-367396:                           #367396
$blue-4E89B3:                           #4E89B3
$blue-1c506f:                           #1c506f
$blue-095989:                           #256391
$blue-17A2B8:                           #17A2B8
$green-EDF8F6:                          #EDF8F6
$green-4ABDAC:                          #4ABDAC
$green-88BC2A:                          #88BC2A
$green-28A745:                          #28A745
$green-4DC657:                          #4DC657
$green-155724:                          #155724
$orange-F48D52:                         #F48D52
$orange-FE8610:                         #FE8610
$orange-FC4A1A: #d22f00
$pink-E4C1C7:                           #E4C1C7
$pink-FEC8BA:                           #FEC8BA
$pink-ebd8d8:                           #ebd8d8
$red-BF2827:                            #BF2827
$red-D0021B:                            #D0021B
$pp-pink:                               #ff4ac7
$yellow-FFC107:                         #FFC107
$chamois-EFDCB4:                        #EFDCB4
$brown-8F7540:                          #8F7540
$brown-949494:                          #949494
$black-1F2532:                          #1F2532
$black-343A40:                          #343A40
$black-000000:                          #000000
$pp-brown:                              #9b5b00
$inbank-color:                          #2b0a58
$map-stroke:                            #aca581
$map-hover:                             #e89d00
$christmas-green:                       #008000
$telegram-color:                        #1c93e3
$trusted-seller:                        #29ba3f

$pp-orange:                             $orange-FC4A1A
$pp-yellow:                             #f89f43
$pp-green:                              $green-4ABDAC
$pp-blue:                               $blue-295e7e
$pp-black:                              $black-000000
$pp-white:                              $white-FFFFFF
$pp-gray:                               $gray-828081
$pp-error:                              $orange-FC4A1A
$info:                                  $pp-yellow

//Component colors ($pp-component_name)
$pp-footer:                             #EEEEEE
$pp-menu-blue:                          $brand-color
$pp-input-placeholder:                  $gray-828081
$pp-gray-border:                        $gray-DEDEDE
$pp-icon:                               $gray-CCCCCC
$pp-light-border:                       $gray-E8E8E8
$pp-myads:                              $green-4ABDAC
$pp-compare:                            #e55dae
$pp-filters:                            $green-88BC2A
$pp-bookmarks:                          $pp-yellow
$pp-chat:                               $orange-FC4A1A
$pp-asterix:                            $orange-FC4A1A
$pp-bookmark-icons:                     $gray-9B9B9B
$pp-blue-border:                        $blue-6197B6
$pp-header-search:                      $blue-9AB4C1
$pp-btn-secondary-border:               $gray-CBCBCB
$pp-pipe:                               $gray-9B9B9B
$pp-style-page:                         $gray-A5B5BD
$pp-disabled-input:                     $gray-EAECEF
$pp-password-indicator-default:         $gray-DCD9D7
$pp-password-indicator-very-weak:       $orange-FC4A1A
$pp-password-indicator-weak:            $orange-F48D52
$pp-password-indicator-good:            $blue-17A2B8
$pp-password-indicator-strong:          $green-28A745
$pp-no-feature:                         $gray-9B9B9B
$pp-tip-info:                           $blue-2C90E9
$pp-hover-overlay:                      $black-1F2532
$create-step-bg:                        #FFFFFF
$create-step-bg-active:                 #FFFFFF
$create-breadcrumb-radius:              3rem
$create-breadcrumb-height:              3rem
$create-step-inactive:                  #747474
$create-step-border:                    $create-step-inactive
$pp-bg-grey:                            $gray-EDEDED
$pp-category-picker:                    $gray-9B9B9B
$pp-gold:                               linear-gradient(45deg, #BF953F, #ffd700)
$pp-silver:                             linear-gradient(45deg, #aaa9ad, #eeeeee)
$pp-multicolor:                         linear-gradient(to right, red,orange,yellow,green,blue)
$pp-animal-skin:                        url(../img/color-skin.png)
$pp-breadcrumb-icon:                    $pp-gray
$multi-carousel-controls:               $brown-949494
$multi-carousel-controls-active:        $brown-8F7540
$select-text-color:                     $gray-6f6f6f
$pp-features:                           $pp-footer
$subcategory_bg:                        $pp-white
$pp-gray-icon:                          $pp-icon
$swiper-border-color:                   #cedae8
$skeleton-bg:                           #ebebeb
$skeleton-highlight:                    #f5f5f5
$error-code:                            #f7f7f7

$subcategory_width:                     20rem

$transport-trolley:                     #009de0
$transport-tram:                        #ff000c
$transport-bus:                         #F4B427
$transport-minibus:                     #f6882d
$transport-train:                       $green-88BC2A

$toggle-on:                             $brand-color
$toggle-off:                            $gray-CCCCCC
$toggle-toggler:                        $pp-white
$toggle-width:                          60px
$toggle-height:                         34px
$toggle-toggler-size:                   26px



//Social icon colors
$social-facebook:                       #3A5997
$social-x:                              #000000
$social-whatsapp:                       #25D366
$social-linkedin:                       #0077B5
$social-pinterest:                      #c92229
$social-draugiem:                       #ff6602

//Feedback
$feedback-hate:                         #e12025
$feedback-bad:                          #f47850
$feedback-neutral:                      #fdb040
$feedback-good:                         #91ca61
$feedback-loved:                        #39b649
$feedback-gray:                         #CCCCCC
$feedbacks:                             hate, bad, neutral, good, loved

//////////////////////////////////////
//Overwriting default module variables
$ig-blue:                               $pp-yellow
$success:                               $green-155724
$progress-bar-bg:                       $blue-37759A
$progress-bg:                           lighten($blue-37759A, 45%)
$link-color:                            $pp-black
$link-decoration:                       underline
$form-group-margin-bottom:              0

//Fixing module icon content
$fa-angle-down:                         "\f101"  //pp-a-angle-down
$plan_private:                          "\f102"  //pp-a-avatar
$rct-icon-check:                        "\f103"  //pp-a-check-square
$rct-icon-parent-close:                 "\f104"  //pp-a-folder-o
$rct-icon-expand-open:                  "\f105"  //pp-a-minus-square-o
$image-gallery-play:                    "\f106"  //pp-a-play
$rct-icon-expand-close:                 "\f107"  //pp-a-plus-square-o
$rct-icon-uncheck:                      "\f108"  //pp-a-square-o
$rct-icon-leaf:                         "\f101"  //pp-a-file-text-o
$rct-icon-parent-open:                  "\f102"  //pp-a-folder-open-o

//Theme colors
$black:                                 $black-000000
$blue:                                  $blue-007BFF
$red:                                   $orange-FC4A1A
$yellow:                                $yellow-FFC107
$green:                                 $green-28A745
$cyan:                                  $blue-17A2B8
$gray-100:                              $gray-F8F9FA
$gray-800:                              $black-343A40
$theme-colors:                          (primary: $pp-yellow, secondary: $white-FFFFFF, success: $green, info: $cyan, warning: $yellow, danger: $red, light: $gray-100, dark: $gray-800)

//Breakpoints and container widths
$xs-breakpoint:                         0
$sm-breakpoint:                         480px
$md-breakpoint:                         768px
$lg-breakpoint:                         1024px
$xl-breakpoint:                         1280px
$xxl-breakpoint:                        1500px

$sm-container:                          480px
$md-container:                          750px
$lg-container:                          1000px
$xl-container:                          1250px
$xxl-container:                         1470px

$xs-masonry:                            50%
$sm-masonry:                            50%
$md-masonry:                            50%
$lg-masonry:                            33.333%
$xl-masonry:                            25%
$xxl-masonry:                           220px
$xxl-masonry-override:                  245px
$lg-masonry-full-width:                 250px
$xl-masonry-full-width:                 208px
$xxl-masonry-full-width:                210px

//Map View
$map-view-header:                       4rem

$xs-masonry-map-view:                   50%
$sm-masonry-map-view:                   33.333%
$md-masonry-map-view:                   100%
$lg-masonry-map-view:                   50%
$xl-masonry-map-view:                   33.333%

$xs-left-side-map-view:                 100%
$xs-right-side-map-view:                $xs-left-side-map-view
$sm-left-side-map-view:                 100%
$sm-right-side-map-view:                $sm-left-side-map-view
$md-left-side-map-view:                 25%
$md-right-side-map-view:                100% - $md-left-side-map-view
$lg-left-side-map-view:                 40%
$lg-right-side-map-view:                100% - $lg-left-side-map-view
$xl-left-side-map-view:                 690px
$xl-right-side-map-view:                calc(100% - 690px)

$sm_height:                             900px

$marker-cluster-large:                  url(../img/leaflet/marker-cluster-large.png)
$marker-cluster-medium:                 url(../img/leaflet/marker-cluster-medium.png)
$marker-cluster-small:                  url(../img/leaflet/marker-cluster-small.png)
$marker-arrow:                          $blue-295e7e
$marker-single:                         $blue-2d90e8
$marker-not-accurate:                   url(../img/leaflet/marker-not-accurate.png)
$marker-logo:                           url(../img/leaflet/marker.png)
$marker-parcel-omniva:                  url(../img/leaflet/marker-parcel-omniva.png)
$marker-cluster-omniva:                 url(../img/leaflet/marker-cluster-omniva.png)

//Changing breakepoints
$grid-breakpoints:                      (xs: $xs-breakpoint, sm: $sm-breakpoint, md: $md-breakpoint, lg: $lg-breakpoint, xl: $xl-breakpoint, xxl: $xxl-breakpoint)

//Define the maximum width of `.container`
$container-max-widths:                  (sm: $sm-container, md: $md-container, lg: $lg-container, xl: $xl-container, xxl: $xxl-container)

$grid-gutter-width:                     0.5rem
$grid-gutter-width-half:                math.div($grid-gutter-width, 2)

//Header heights
$header-height-lg:                      4rem
$header-height-sm:                      4rem
$footer-height:                         5.5rem
$footer-height-sm:                      9rem
$header-search-height-xs:               2.625rem
$header-search-bg:                      $white-FFFFFF
$header-right-width:                    18.5rem
$search-results-height:                 1000px
$search-icon-mobile:                    1.7rem

//Menu
$menu-nav-item-height-mobile:           4rem

//Fonts
$font-baseline_px:                      16px
$font-family-base:                      system-ui, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"
$font-size-sm:                          0.625rem
$h1-font-size:                          3rem
$h2-font-size:                          1.5rem
$h3-font-size:                          1.125rem
$h4-font-size:                          1rem
$h5-font-size:                          .8rem
$h6-font-size:                          .7rem
$headings-font-weight:                  300

//Inputs
$form-label-color:                      $pp-black
$form-input-height:                     2.5rem
$form-input-bg:                         $pp-white
$form-input-border:                     #d8d8d8
$form-select-bg:                        $form-input-bg
$form-select-bg-size:                   14.4px
$form-select-color:                     $pp-black
$form-select-border-width:              1px
$form-select-border-radius:             0
$form-select-border-color:              $form-input-border
$input-border-radius-lg:                0.15rem
$input-border-radius:                   0
$input-border-color:                    $form-select-border-color
$input-border-focus:                    $pp-yellow
$input-border-width:                    1px
$input-btn-border-width:                1px
$input-padding-y:                       0.5rem
$input-padding-x:                       0.5rem
$form-select-padding-y:                 0.5rem
$pp-placeholder-default:                $gray-A7A6A6
$input-color:                           #000000
$custom-control-active-indicator-bg:    $pp-yellow
$custom-control-indicator-bg:           $white-FFFFFF
$hr-border-color:                       $gray-CCCCCC
$hr-border-width:                       2px
$border-color:                          $gray-EDEDED
$input-btn-line-height:                 1
$custom-control-indicator-bg-size:      60%
$custom-control-indicator-size:         1rem
$custom-select-option-height:           2.75rem
$input-font-weight:                     300

$fs-form-fieldset:                      0.85rem
$form-select-line-height:               1.2
$form-select-indicator-color:           $pp-yellow
$form-select-indicator:                 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='150' height='96.309'%3E%3Cpath d='M148.232 25.59L79.187 94.54C78.01 95.72 76.613 96.31 75 96.31c-1.613 0-3.009-.59-4.187-1.768L1.768 25.589C.589 24.411 0 23 0 21.355c0-1.643.59-3.055 1.768-4.233L15.014 6.118c1.179-1.179 2.574-1.768 4.187-1.768 1.613 0 2.994.605 4.188 1.768L75 56.375l50.934-49.919c1.19-1.167 2.575-1.768 4.188-1.768 1.612 0 3.008.59 4.187 1.768l13.923 10.666c1.179 1.178 1.768 2.59 1.768 4.233 0 1.644-.59 3.056-1.768 4.234z' fill='%23f89f43'/%3E%3C/svg%3E")
$form-check-input-checked-bg-color:     $pp-yellow
$form-check-input-border:               1px solid $pp-yellow
$form-check-input-focus-border:         tint-color($pp-yellow, 50%)
$form-check-input-focus-box-shadow:     0 0 0 0.25rem rgba($pp-yellow, 0.25)
$form-legend-color:                     #667284
$component-active-bg:                   $pp-yellow
$form-label-margin-bottom:              0

//Buttons
$btn-primary-bg:                        $pp-yellow
$btn-primary-border:                    $pp-yellow
$btn-primary-color:                     $pp-yellow
$btn-focus-box-shadow:                  0 10px 25px rgba($black, .15)
$btn-border-radius-sm:                  0.5rem
$btn-border-radius:                     0.5rem
$badge-border-radius:                   0.5rem
$pagination-border-radius:              0.5rem
$btn-font-size:                         0.85rem
$btn-font-size-secondary:               0.75rem
$btn-padding-x:                         0.75rem
$btn-padding-y:                         0.75rem
$btn-padding-y-sm:                      0.438rem
$input-btn-padding-y:                   0.75rem
$input-btn-padding-x:                   0.75rem
$btn-secondary-icon:                    $gray-828081
$btn-action:                            $bg-color
$btn-action-hover:                      $gray-ECECEC
$btn-action-hover-border:               $gray-A8A8A8
$btn-line-height-sm:                    1
$btn-disabled-opacity:                  0.3
$btn-is-on-border:                      $pp-yellow
$btn-is-on-bg:                          #fff8e3
$btn-font-weight:                       600
$btn-box-shadow:                        0 10px 25px rgba($black, .15)
$btn-height-mobile:                     2.5rem
$btn-min-width-mobile:                  2.5rem
$btn-font-size-mobile:                  1rem

//Modals
$popover-opacity:                       0.9
$modal-xl:                              100%
$modal-lg:                              100%
$modal-md:                              100%
$modal-sm:                              100%
$modal-xs:                              100%
$modal-dialog-sm-up-margin-y:           0
$modal-dialog-margin:                   0
$border-radius-lg:                      0
$modal-content-border-width:            0
$modal-content-bg:                      rgba($pp-menu-blue, $popover-opacity)
$modal-header-padding:                  2rem 3rem
$modal-backdrop-bg:                     rgba($pp-menu-blue, 0.5)
$modal-backdrop-opacity:                1
$modal-inner-padding:                   1rem
$modal-dialog-margin-y-sm-up:           0

//Alerts
$alert-warning-bg:                      $chamois-EFDCB4
$alert-warning-border:                  $pp-yellow
$alert-warning-text:                    $black-000000
$warning-icon:                          $pp-yellow
$alert-danger-bg:                       $pink-FEC8BA
$alert-danger-border:                   $orange-FC4A1A
$alert-danger-text:                     $black-000000
$alert-border-radius:                   0.15rem
$danger-icon:                           $orange-FC4A1A
$alert-padding-x:                       0.75rem
$alert-padding-y:                       0.5rem
$alert-margin-bottom:                   0

//Pagination
$pagination-bg:                         $btn-action
$pagination-border-color:               $pp-gray-border
$pagination-color:                      $pp-gray
$pagination-active-bg:                  $pagination-bg
$pagination-active-color:               $pp-black
$pagination-active-border-color:        $pp-yellow
$pagination-disabled-bg:                $pagination-bg
$pagination-hover-color:                $pp-black
$pagination-hover-border-color:         $btn-action-hover-border
$pagination-hover-bg:                   $btn-action-hover
$pagination-disabled-color:             rgba($pp-gray, 0.5)
$pagination-padding-y:                  0.4rem
$pagination-padding-x:                  0.7rem
$border-radius:                         0.15rem

//Badges
$badge-default-bg:                      $orange-FC4A1A
$badge-font-weight:                     300
$badge-font-size:                       100%
$badge-padding-y:                       0.15rem

//Dropdowns
$dropdown-border-width:                 1px
$dropdown-padding-y:                    0
$dropdown-bg:                           $white-FFFFFF
$dropdown-header-bg:                    #f2f2f2
$box-shadow-multilevel-category-menu:   0 0 10px 0rem rgb(0 0 0 / 30%)

//Tables
$table-cell-padding:                    .2rem

//Image sizes
$med-width:                             640px
$med-height:                            360px
$big-width:                             1280px
$big-height:                            720px
$image-gallery-height-xl:               38.75rem
$image-gallery-height-lg:               28rem
$image-gallery-height-xs:               17.375rem

//Cards
$card-border-radius:                    2px
$card-bg:                               transparent
$card-columns-gap:                      .25rem

//Slider
$slider-inner:                          $white-FFFFFF
$slider-border:                         2px solid $pp-yellow
$slider-active-background:              $pp-yellow
$slider-size:                           1rem
$slider-inactive:                       $gray-D8D8D8

//React select variables
$select-input-height:                   44px
$select-menu-max-height:                295px
$select-arrow-width:                    7px
$select-arrow-color:                    $pp-yellow
$select-arrow-color-hover:              darken($pp-yellow, 10%)
$select-option-small-screen:            369px

//Preloader
$pp-loader-grey:                        $gray-9B9B9B
$radial-start-color:                    $blue-AEC1D0
$radial-center-color:                   $gray-F3F3F3
$radial-last-color:                     $blue-DDE4EA
$outer-circle:                          $blue-CFD9E2
$inner-circle:                          $blue-4E89B3
$loader-bg:                             $blue-095989
$loader-svg-color:                      #5984b1
$loader-bg-color:                       #dee6ee

//React tree
$rct-collapse-size:                     1rem
$checkbox-size:                         1.15rem

//Validation Icon
$enable-validation-icons:               false
$rct-collapse-size:                     1rem

//Custom checkboxes
$custom-radio-button:                   str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$pp-yellow}'/%3e%3c/svg%3e"), "#", "%23")

//Custom radiobutton
$custom-control-indicator-bg-size:      50% 50%

//Tabs
$tab-border-color:                       #DEDEDE
$tab-background-color:                   #FAFAFA
$tab-icon-color:                         #76abcb

//Bootstrap variables
$bs-body-bg:                            $white-FFFFFF
$bs-modal-bg:                           $brand-color
$bs-modal-color:                        $white-FFFFFF
$bs-btn-bg:                             $btn-secondary-bg
$bs-link-color:                         $link-color
$bs-btn-active-border-color:            $pp-btn-secondary-border
$bs-dropdown-border-color:              rgba($pp-black, 0.176)
$enable-cssgrid:                        true

//Zindex
$zindex-compare-close:                  4
$zindex-dropdown:                       5
$zindex-multilevel-category-menu:       7
$zindex-map-view-header:                401
$zindex-scroll-to-top:                  500
$zindex-leaflet-controls:               1049
$zindex-custom-select:                  1050
$zindex-main-header:                    1051
$zindex-fixed-controls:                 1051
$zindex-dropup-menu:                    1052
$zindex-feedback:                       1053
$zindex-mobile-dropdown:                1100
$zindex-mobile-price:                   1200
$zindex-mobile-sticky-header:           1201
$zindex-modal-backdrop:                 1202
$zindex-gdpr:                           1298
$zindex-sidemenu-overlay:               1299
$zindex-sidemenu:                       1300
$zindex-modal:                          1300
$zindex-modal-open:                     1350
$zindex-tooltip:                        1400
$zindex-fullscreen-modal:               1500
$zindex-fullscreen-zoom:                100000

//Autego leasing calculator
$autego-checkbox:                       str-replace(url('data:image/svg+xml,<svg width="20mm" height="20mm" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect style="fill:%23ee3231;fill-opacity:1;stroke-width:.454988" width="10" height="10" x="83.949" y="127.617" ry="2.105" transform="translate(-78.95 -122.617)"/></svg>'), "#", "%23")
$autego-red:                            #EE3231
$autego-yellow:                         #FFE764
$autego-input-placeholder:              #ACACAC
$autego-white:                          $pp-white

//Masonry lot
$masonry-height:                        295px
$masonry-text:                          #5b5b5b
$masonry-header-color:                  #213b5a
$masonry-header-font-weight:            500
$masonry-header-font-size:              1rem
$masonry-border-radius:                 0.25rem
$masonry-menu-height:                   19.7rem

$xs-parent-masonry:                     100%
$sm-parent-masonry:                     47.8%
$md-parent-masonry:                     231px
$lg-parent-masonry:                     314px
$xl-parent-masonry:                     293px
$xxl-parent-masonry:                    245px

$pp-masonry-blocked-border:             $red-BF2827
$pp-masonry-blocked-bg:                 rgba($red-D0021B,0.1)
$pp-masonry-blocked-bg-footer:          $pink-ebd8d8
$pp-masonry-blocked-footer-border:      $pink-E4C1C7
$input-focus-border-color:              $pink-E4C1C7
$input-focus-box-shadow:                0 0 0 .25rem rgba($component-active-bg, .25)
$gray-900:                              #0d1117

$price-offset:                          65px
$scroll-to-top-offset:                  200
