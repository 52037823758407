//select.form-control
  display: block
  font-size: 1rem
  font-weight: 300
  color: $pp-black
  line-height: 1.3
  padding: .6rem 2.2rem .5rem .8rem
  width: 100%
  max-width: 100%
  box-sizing: border-box
  margin: 0
  box-shadow: 0 1px 0 1px rgba($pp-black,.04)
  appearance: none
  background-color: $pp-white
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='150' height='96.309'%3E%3Cpath d='M148.232 25.59L79.187 94.54C78.01 95.72 76.613 96.31 75 96.31c-1.613 0-3.009-.59-4.187-1.768L1.768 25.589C.589 24.411 0 23 0 21.355c0-1.643.59-3.055 1.768-4.233L15.014 6.118c1.179-1.179 2.574-1.768 4.187-1.768 1.613 0 2.994.605 4.188 1.768L75 56.375l50.934-49.919c1.19-1.167 2.575-1.768 4.188-1.768 1.612 0 3.008.59 4.187 1.768l13.923 10.666c1.179 1.178 1.768 2.59 1.768 4.233 0 1.644-.59 3.056-1.768 4.234z' fill='%23f7b733'/%3E%3C/svg%3E")
  -webkit-background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='150' height='96.309'%3E%3Cpath d='M148.232 25.59L79.187 94.54C78.01 95.72 76.613 96.31 75 96.31c-1.613 0-3.009-.59-4.187-1.768L1.768 25.589C.589 24.411 0 23 0 21.355c0-1.643.59-3.055 1.768-4.233L15.014 6.118c1.179-1.179 2.574-1.768 4.187-1.768 1.613 0 2.994.605 4.188 1.768L75 56.375l50.934-49.919c1.19-1.167 2.575-1.768 4.188-1.768 1.612 0 3.008.59 4.187 1.768l13.923 10.666c1.179 1.178 1.768 2.59 1.768 4.233 0 1.644-.59 3.056-1.768 4.234z' fill='%23f7b733'/%3E%3C/svg%3E")
  background-repeat: no-repeat, repeat
  -webkit-background-size: .9rem auto, 100%
  background-position: right 1rem top 50%, 0 0
  background-size: .9rem auto, 100%
  cursor: pointer
  transition: 0.25s linear

select.form-control.opened
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='transform:rotate(180deg);' width='150' height='96.309'%3E%3Cpath d='M148.232 25.59L79.187 94.54C78.01 95.72 76.613 96.31 75 96.31c-1.613 0-3.009-.59-4.187-1.768L1.768 25.589C.589 24.411 0 23 0 21.355c0-1.643.59-3.055 1.768-4.233L15.014 6.118c1.179-1.179 2.574-1.768 4.187-1.768 1.613 0 2.994.605 4.188 1.768L75 56.375l50.934-49.919c1.19-1.167 2.575-1.768 4.188-1.768 1.612 0 3.008.59 4.187 1.768l13.923 10.666c1.179 1.178 1.768 2.59 1.768 4.233 0 1.644-.59 3.056-1.768 4.234z' fill='%23f7b733'/%3E%3C/svg%3E")
  -webkit-background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='transform:rotate(180deg);' width='150' height='96.309'%3E%3Cpath d='M148.232 25.59L79.187 94.54C78.01 95.72 76.613 96.31 75 96.31c-1.613 0-3.009-.59-4.187-1.768L1.768 25.589C.589 24.411 0 23 0 21.355c0-1.643.59-3.055 1.768-4.233L15.014 6.118c1.179-1.179 2.574-1.768 4.187-1.768 1.613 0 2.994.605 4.188 1.768L75 56.375l50.934-49.919c1.19-1.167 2.575-1.768 4.188-1.768 1.612 0 3.008.59 4.187 1.768l13.923 10.666c1.179 1.178 1.768 2.59 1.768 4.233 0 1.644-.59 3.056-1.768 4.234z' fill='%23f7b733'/%3E%3C/svg%3E")

select.form-control.repaint
  transform: none

select.form-control::-ms-expand
  display: none

select.form-control:focus
  box-shadow: none
  color: $pp-black
  outline: none

select.form-control
  option
    font-weight: normal
.select-wrapper
  position: relative
  i
    position: absolute
    font-size: 1.8rem!important
    top: .7rem
    left: .5rem
  select
    padding-left: 3rem
