.badge
  color: $pp-white
  font-size: 0.8rem
  font-weight: normal
  min-width: 1.5rem

.badge-default
  background-color: darken($pp-gray, 10%)
  color: $pp-white
  min-width: auto
  margin-right: 0.3rem

.badge-admin
  background-color: $pp-blue
  color: $pp-white

.badge-myads,
.badge-bookmarks,
.badge-filters,
.badge-chat,
.badge-pink,
.badge-compare
  +rem("font-size", 12px)

.badge-myads
  background-color: $pp-myads

.badge-compare
  background-color: $pp-compare

.badge-bookmarks
  background-color: $pp-yellow

.badge-filters
  background-color: $pp-filters
  color: $pp-white!important

.badge-filters-inverted
  color: $pp-filters
  background-color: $pp-white

.badge-chat
  background-color: $pp-chat

.badge-tram
  background-color: $transport-tram

.badge-bus
  background-color: $transport-bus

.badge-trolley
  background-color: $transport-trolley

.badge-minibus
  background-color: $transport-minibus

.badge-train
  background-color: $transport-train

.badge-pink
  background-color: $pp-pink

.badge-type
  background-color: $pp-yellow

.round-badge
  position: relative
  .badge
    position: absolute
    min-width: 1.25rem
    top: 0.75rem
    z-index: 1
    border-radius: 50%
    padding: .2rem
    &.badge-compare
      left: -.5rem

.bookmarks-count
  position: absolute
  top: -.5rem
  left: 0
  right: 0
  margin: 0 .35rem
  padding: 0

.badge-recommended
  position: absolute
  z-index: 1
  background: linear-gradient(90deg, rgba($recommended-border,1) 0%, rgba($recommended-gradient,1) 20%)
  top: 1.25rem
  font-size: .85rem
  padding: 0.25rem .65rem .25rem .25rem
  border-bottom-right-radius: .25rem
  border-top-right-radius: .25rem
  color: $pp-white
  i
    color: $pp-white
