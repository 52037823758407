//.category-dropdown,
//Search
.Select-menu-outer,
.manual-cat
  line-height: 1.25
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1)
  background-color: var(--header-search-bg)
  list-style: none
  width: 100%
  z-index: 1001
  //margin: 2px -1px 0
  li,
  .search-option,
  .selected-category
    font-size: 1rem
    font-weight: 100
    line-height: 1.5
    border-bottom: 2px solid var(--pp-bg-gray)!important
    cursor: pointer
    height: $custom-select-option-height
    overflow: hidden
    display: flex
    align-items: center
    padding: 0.5rem
    &.is-selected
      background-color: var(--pp-bg-gray)
    &.is-focused
      background-color: var(--pp-bg-gray)
    &:last-of-type
      border: none
      font-style: normal
    &.is-disabled
      min-height: auto
      border-bottom: .5rem solid
      top: -1px
      position: relative
      pointer-events: none
    .pp-landing,
    .pp-category
      font-size: 1.25rem
      width: 2rem
      display: table-cell
      margin-right: .5rem
    .pipe
      border-color: $pp-pipe
      margin-right: .5rem
  &:focus
    outline: none
  .Select-clear
    font-style: normal
    font-weight: bold
    color: $btn-primary-color
  .Select-clear-zone
    vertical-align: text-top

.Select-placeholder
  line-height: 1.2

.manual-select
  font-weight: 100
  color: $pp-placeholder-default
  font-style: italic
  line-height: 1.5
  .selected-category
    +rem('font-size', 18px)
    color: $black
    font-weight: bold
    font-style: normal
    letter-spacing: 1.5px
    text-transform: uppercase
    position: relative
    top: -3px
    &.is-selected
      background-color: var(--pp-bg-gray)
    &.is-focused
      background-color: $pp-gray-border
    .pp-landing,
    .pp-category
      font-size: 1.25rem
      width: 2rem
      display: table-cell

.pp-select
  .btn
    padding: 0.5rem
    text-align: left
    color: $pp-icon
    +rem("font-size", 12px)
    +rem("min-width", 200px)
    display: flex
    height: 2.2rem
    align-items: baseline
    &:after
      display: none
  .dropdown-divider
    margin: 0
  i
    color: $pp-yellow
    font-size: 1rem
    font-weight: bold
    text-shadow: -1px 0 $pp-yellow, 0 1px $pp-yellow, 1px 0 $pp-yellow, 0 -1px $pp-yellow
    padding: 0
  //.form-control
    height: initial
  //select
    &.form-control
      height: 3.1rem
  .Select-arrow-zone
    border: none
  .Select--multi
    padding: 0 .75rem!important
    &.has-value
      padding: .75rem!important
    .Select-input
      margin: 0
    .Select-value
      margin: 0 .25rem .25rem 0
      background-color: $pp-yellow
      border: 1px solid $pp-yellow
      color: $pp-white
      font-style: normal
      display: inline-flex
      .Select-value-label,
      .Select-value-icon
        height: auto
        font-size: .85rem
        font-weight: normal
        padding: 0 5px
        cursor: pointer
    .Select-clear
      font-style: normal
    .Select-value-icon
      padding: 2px 5px
      border-right: 1px solid lighten($pp-yellow, 10%)
      &:hover,
      &:focus
        background-color: darken($pp-yellow, 10%)
        color: $pp-white
    &.has-value
      .Select-input
        top: initial
        padding-top: 1px
        > input
          padding: 0
  .Select-control
    display: flex
    align-items: center
  .Select-menu-outer
    z-index: 5
  .Select-input
    padding-left: 0

.has-checkbox
  margin: 0
  .search-option
    border-bottom: 0
    &.custom-checkbox
      padding-left: 1.5rem
      display: flex
      cursor: pointer
      position: relative
      .form-check-input
        margin-left: -1rem
        margin-top: -0.25rem
      .form-check-label
        display: flex
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        align-items: center
        padding-left: 2rem
        padding-top: 0
        cursor: pointer
        border-bottom: 1px solid $pp-gray-border
        &:before,
        &:after
          left: .5rem
          top: .75rem

.custom-checkbox
  &.form-group
    margin-bottom: 0
    margin-top: .25rem

.category-picker
  &.Select
    &.has-value
      &.Select--single
        height: auto
        > .Select-control
          .Select-value
            display: inline-block
            position: inherit
            margin-right: auto
            margin-bottom: .25rem
            background-color: $pp-category-picker
            border: 1px solid $pp-category-picker
            color: $pp-white
            font-style: normal
            font-size: 0.9em
            border-radius: .2rem
            white-space: nowrap
            padding: .06rem .7rem
            cursor: pointer
            .Select-value-label
              +rem("font-size", 13px)
              font-weight: 500
              color: $pp-white
              text-transform: uppercase
              display: flex
              align-items: center
              +rem("height", 24px)
              .pp-ico
                color: $pp-white
                text-shadow: none
                font-size: 1.5rem
                font-weight: normal
                padding-right: .5rem
              span
                padding-top: .2rem
                width: 100%
  &.pp-select
    .Select-control
      padding: .5rem 2.8rem .3rem .5rem
    .Select-value
      .pp-angle-down
        color: $pp-white
        text-shadow: -1px 0 $pp-white, 0 1px $pp-white, 1px 0 $pp-white, 0 -1px $pp-white
        padding-left: .5rem
        border-left: 1px solid $pp-white
        margin-left: .8rem
        font-weight: normal
  .Select-menu
    max-height: 37.2rem
  .Select-menu-outer
    max-height: 37.5rem
  .preloader-wrapper
    margin-top: -.85rem

.empty-select
  .Select-menu-outer
    display: none

.custom__select
  line-height: 1.25
  background-color: var(--header-search-bg)
  list-style: none
  width: 100%
  z-index: $zindex-custom-select
  border: 1px solid var(--border-color)
  border-top-color: var(--border-color)
  //box-shadow: 0 1px 0 rgba($pp-black,.06)
  box-shadow: 0 4px 4px 1px rgb($pp-black,.1)
  box-sizing: border-box
  margin-top: -1px
  max-height: 295px
  position: absolute
  left: 3px
  top: 100%
  overflow-y: scroll
  &__option
    font-weight: 100
    border-bottom: 2px solid var(--border-color)
    cursor: pointer
    height: $custom-select-option-height
    overflow: hidden
    display: flex
    align-items: center
    padding: 8px 10px
    color: var(--form-select-color)
    @media (max-width: $select-option-small-screen)
      height: fit-content
    &:last-child
      border: none
    &:hover
      background-color: var(--header-search-hover-bg)
    &__item
      width: 100%
      display: flex
      align-items: center
      min-height: $custom-select-option-height
      @media (max-width: $select-option-small-screen)
        min-height: fit-content

.search-select
  .clear-button
    position: absolute
    right: 8px
    top: 50%
    transform: translateY(-50%)
    cursor: pointer

  .dropdown-menu
    position: absolute
    top: 100%
    left: 0
    right: 0
    z-index: 1000
    max-height: 200px
    overflow-y: auto
    display: block
    list-style: none
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
    background: var(--header-search-bg)

  .dropdown-item
    padding: 8px 12px
    cursor: pointer
    &:hover
      background-color: var(--header-search-hover-bg)

  .dropdown-item.selected
    background-color: #e0e0e0
