@use "sass:math"
.custom-actions,
.transport-filter
  padding: 0
  display: inline-flex
  position: relative
  .form-check-input
    position: absolute
    left: 2rem
    top: 0.5rem
    ~ .form-check-label
      +pp-box
      height: $form-input-height
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      flex-grow: 1
      font-size: .875rem
      font-weight: 500
      padding: 0 2rem 0 2.5rem
      color: var(--masonry-text)
      &:before,
      &:after
        display: none
      i
        color: $pp-yellow
        top: .8rem
        left: .6rem
      .pp-ico
        color: $pp-icon
        font-size: 2.5rem
        margin-bottom: .3rem
      &:hover
        background: var(--pp-bg-gray)
        border: 1px solid var(--form-check-label-border)
    &:checked ~ .form-check-label
      color: var(--default-text-color)
      border: 1px solid $pp-yellow
      .pp-ico
        color: $pp-yellow
      .form-check-label
        &:after
          background-image: $custom-radio-button
  //&.custom-actions-sm
    display: flex
    margin: 0 -0.25rem 1rem -0.25rem
    flex-wrap: wrap
    .form-check
      flex: 0 0 50%
      max-width: 50%
      position: relative
      width: 100%
      padding: 0.25rem
      &.full-width
        flex: 0 0 100%
        max-width: none
    .form-check-input
      ~ .form-check-label
        height: 3rem
        width: auto
        text-transform: uppercase
        font-size: .75rem
        span
          display: flex
          align-items: center
          i
            font-size: 1.5rem
            margin-right: .5rem
  &.custom-actions-ico
    .form-check-input
      top: auto
      opacity: 0
    .form-check-label
      height: 5rem
      width: 11.45rem
      padding: 0 .5rem
  &.custom-actions-horizontal
    width: 100%
    .form-check-input
      ~ .form-check-label
        flex-direction: row
        width: 100%
        padding: 1rem 2rem
        justify-content: flex-start
        min-height: 5rem
        i
          font-size: 2.3rem
          color: $pp-icon
        h4
          letter-spacing: 0
          text-transform: none
          margin-bottom: .1rem
        p
          margin: 0
          text-transform: none
          color: $pp-gray
          font-weight: normal
          &.action-select
            +rem("font-size", 18px)
            color: $pp-black
      &:checked
        ~ .form-check-label
          i
            color: $pp-yellow
  .form-check-label
    .form-check-label
      padding-top: .2rem
      font-size: 0.875rem
      height: $form-input-height
      display: flex
      align-items: center
      &:before,
      &:after
        transform: translate(0rem, 70%)
      &.choose-account-radio
        padding-top: .2rem
        margin-left: 2rem
        margin-right: .8rem
        font-size: 0.875rem
        letter-spacing: normal
        height: 100%
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        &:before,
        &:after
          top: 40%
          left: -2rem
          transform: none

.transport-filter
  width: 20%
  margin: 0
  .pp-ico
    line-height: .8
  .form-check-input
    display: none
    ~ .form-check-label
      border-top: none
      border-left: none
      background-color: var(--form-check-label-bg)
      width: 100%
      padding: 0
      border-right: 1px solid var(--form-check-label-border)
      .pp-ico
        text-shadow: none
        font-size: 5rem
        font-weight: normal
        margin-bottom: 0
        color: $gray-7F7F7F
    &:checked
      ~ .form-check-label
        border-color: var(--form-check-label-border)
        border-top: none
        border-bottom-color: var(--bg-color)
        border-left: none
        box-shadow: none
        background-color: var(--bg-color)
        &:hover
          //background: transparent
          cursor: default
          .pp-ico
            color: $pp-yellow
    &:hover
      ~ .form-check-label
        border-top: none
        border-left: none
        .pp-ico
          color: darken($gray-7F7F7F, 10%)
    &:last-of-type
      .form-check-label
        border-right: none
      .form-check-input
        &:checked
          ~ .form-check-label
            border-right: none
        &:hover
          ~ .form-check-label
            border-right: none

.custom-radio
  .form-check-input:checked
    ~ .form-check-label
      &:after
        background-image: $custom-radio-button

      &:before
        background-color: $pp-white

//.grid
//  display: grid
//  grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr))
//  grid-gap: 1rem

.grid-equal
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr))
  .custom-actions
    margin-left: .5rem
    margin-right: 0
    &:first-of-type
      margin-left: 0
    &.custom-actions-ico
      .form-check-input ~ .form-check-label
        width: 100%
        max-width: 12rem

.grid-repeat
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 0.25rem
  .custom-actions
    min-width: 8rem
    margin: 0
    &.custom-actions-ico
      .form-check-input ~ .form-check-label
        width: 100%

.car-body-type
  .form-check-input
    position: absolute
    z-index: -1
    opacity: 0
  .custom-actions
    &.custom-actions-ico
      .form-check-input ~ .form-check-label
        width: 100%
        i
          font-size: 5rem // Default font size
          height: 3rem
          text-shadow: none
          font-weight: normal
          display: flex
          align-items: center

  &.mobile-phone-type
    .custom-actions
      &.custom-actions-ico
        .form-check-input ~ .form-check-label
          i
            font-size: 3rem

.filter-block
  .custom-actions.custom-actions-ico
    .form-check-label
      height: 3.5rem
      .pp-ico
        font-size: 1.5rem

.social-input
  .custom-control-input
    display: none
  .custom-actions .custom-control-input:checked ~ label
    span
      opacity: 1
      cursor: default!important

.action-choose
  --bs-gutter-x: 0.5rem
  flex-direction: row
  margin-right: -$grid-gutter-width
  margin-left: -$grid-gutter-width
  .custom-actions
    flex: 0 0 auto
    width: 50%
    margin-right: 0
    padding-right: math.div($grid-gutter-width,2)
    padding-left: math.div($grid-gutter-width,2)
    margin-bottom: $grid-gutter-width
