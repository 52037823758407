.notification-success
  .pp-ico
    color: $green-4DC657
    padding-left: .5rem
    padding-top: .8rem

.notification-error
  .pp-landing
    color: $red-BF2827
    padding-left: .5rem
    padding-top: .8rem
    &:before
      content: "\00d7"
