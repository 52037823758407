body
  transition: background-color 0.5s ease, color 0.5s ease
.menu-right-wrapper
  font-size: 0.8rem
  padding: 0 1.5rem
  color: white
  background-color: var(--pp-menu)
  position: fixed
  right: -400px
  top: 0
  height: 100%
  +rem("width", 320px)
  z-index: $zindex-sidemenu
  visibility: hidden
  transition: 200ms ease
  &:focus
    outline: 0
  &.show-menu-right
    right: 0
    visibility: visible
    overflow-y: auto
    box-shadow: 1px 0 8px 6px rgba($pp-black, 0.2)
    .parent-category
      color: var(--inverted-link-color)
      line-height: 2
  .pp-landing
    font-size: 1rem
    font-weight: 500
    //color: var(--menu-icon-color)
    &.pp-bars,
    &.pp-close-thin
      font-size: 1.2rem
      text-align: center
      padding: 1rem
    &.pp-close-thin
      font-size: 1.5rem
  .social-follow
    i
      color: $pp-white
      &:hover
        color: var(--menu-icon-color-hover)
  .close-thin
    position: absolute
    right: .5rem
    cursor: pointer
  .btn-only-icon
    color: $pp-white
    &:hover
      i
        color: $pp-white
  .menu-profile
    height: $header-height-lg
    display: flex
    align-items: center
    .media
      padding-top: .4rem
    .media-body
      padding: 0 .3rem
      text-overflow: ellipsis
      max-width: 70%
      white-space: nowrap
      overflow: hidden
      strong
        display: block
    .profile-image-cropper
      top: 0
    .btn-primary
      height: 2.6rem
      display: flex
      align-items: center
      i
        color: $pp-white
    &.menu-right-block
      border: none
  .menu-right-block
    +rem("font-size", 12px)
    letter-spacing: 1px
    padding: 1rem 0
    border-top: solid var(--menu-border-color) 1px
    text-transform: uppercase
    .pp-lang
      width: 1rem
      margin-top: .1rem
      background-position: 0 0
    span
      width: 1.5rem
    a, .sign-out
      display: flex
      flex-wrap: nowrap
      padding: .5rem
      cursor: pointer
      font-weight: 100
      &:hover
        background: rgba($pp-black, .1)
        i
          color: var(--menu-icon-color-hover)
    .badge
      min-width: 1.7rem
      margin: 0
      width: auto
    .menu-right-lang
      padding: 10px 0
      cursor: pointer
    &.my-menu
      a
        font-weight: 500
    img
      padding: 5px 0 0 0
  .media
    strong,
    span
      +rem("font-size", 12px)
  a
    color: inherit
    text-decoration: inherit
  .block
    color: var(--inverted-text-color)
  .category__tree
    div
      padding-top: .25rem

.overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: $zindex-sidemenu-overlay
  backdrop-filter: blur(2px)
.overlay-mobile
  @extend .overlay
  backdrop-filter: none
  background-color: rgba(0, 0, 0, 0.5)

.sell-on-pp
  color: var(--masonry-text)
  font-size: .85rem
  .btn
    text-transform: none
    font-weight: normal
