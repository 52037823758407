@import ../base/variables
@import ../base/mixins
.header-avatar
  .profile-image-cropper
    top: 14px

.container-fluid.top-header-container
  color: var(--masonry-price)
  background-color: var(--header-bg)
  height: $header-height-lg
  position: sticky
  top: 0
  z-index: $zindex-main-header
  margin-bottom: .25rem
  .badge
    display: block
  a
    text-decoration: inherit
  &.ac-open
    .header-middle-wrapper
      .header-middle-input
        display: block
        padding-right: 8rem

.header-middle-wrapper
  height: $header-height-lg

.header-right
  font-size: 1.2rem
  padding: 0 0 0 .8rem
  min-width: $header-right-width
  .button-myads,
  .button-my-company,
  .button-bookmarks,
  .button-filters,
  .button-chat,
  .button-compare
    +rem("min-width", 35px)
    height: 3rem
    padding-bottom: .3rem
    display: flex
    flex-direction: column
    justify-content: flex-end
    text-align: center
  .badge-myads,
  .badge-bookmarks,
  .badge-filters,
  .badge-chat,
  .badge-compare
    text-align: center
    margin: .3rem 0 .3rem 0
    min-width: fit-content
    color: $pp-white
    padding: 0
  .header-right-lang
    padding: 0 8px
    display: inline-block
    > img
      display: inline-block
      padding-right: 2px
      cursor: pointer
    > span
      text-decoration: underline
      font-size: 0.8rem
      display: inline-block
      cursor: pointer
  > i, a
    display: inline-block
    cursor: pointer
    position: relative
    vertical-align: bottom
    > i
      padding: 0 8px
  i
    color: $header-icons
  .btn-burger
    width: 44px
    padding: 0
    &:hover
      i
        color: var(--btn-burger-hover-color)
    i
      position: relative
      padding: 1rem 0
      cursor: pointer
      font-size: 2rem
      width: 32px
      color: var(--btn-burger-color)
      &:hover
        color: var(--btn-burger-hover-color)

.header-logo-wrapper
  position: relative
  display: flex
  flex-direction: row
  align-content: stretch
  height: $header-height-lg
  padding-right: .5rem
  .powered-by
    float: left
    line-height: 3rem
    margin-right: .5rem
  .title-main
    cursor: pointer
    width: 9rem
    height: 2.5rem
    svg
      max-width: 100%
      max-height: 100%
    .title-image
      display: block
    .title-image-mobile
      display: none

.header-right-lang
  .pp-lang
    padding: 0.35rem 0.65rem 0.4rem 0.65rem
    background-size: 1rem
    &.ru
      background-position: 0 -0.75rem

.header-language
  text-transform: uppercase
  +rem("font-size", 18px)
  font-weight: 100
  line-height: 1
  padding: 1.5rem .5rem 0
  color: $link-light
  &:hover
    color: $pp-white

.social-link
  padding-top: 1.2rem

.language-switch
  display: flex
  align-items: center
  a
    display: flex
    align-items: center
