.select-color
  width: 1.5rem
  height: 1.25rem
  border-radius: .2rem
  border: 1px solid $gray-EDEDED
  margin-right: .5rem
  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba($pp-white,0.5))

.gold
  background: $pp-gold

.silver
  background: $pp-silver

.multicolor
  background-image: $pp-multicolor

.animal-skin
  background-image: $pp-animal-skin
