html
  font-size: $font-baseline_px
  scroll-behavior: auto !important
  overflow-y: scroll

body
  min-width: 320px
  overflow: visible!important
  padding-right: 0!important
  a
    &:focus
      color: unset
      text-decoration: unset
    &:hover
      color: unset
      text-decoration: unset
  input,
  button
    font-family: $font-family-base

//h1,
//h2,
//h3,
//h4,
//h5
//  text-transform: uppercase
//  font-weight: bold
//  +rem('letter-spacing', 0.8px)

label
  cursor: pointer

.d-grid
  display: grid

.grid-column-repeat
  grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr))

.grid-template-columns-2
  grid-template-columns: repeat(2, 1fr) !important

.main-wrapper
  min-height: calc(100vh - #{$footer-height} - #{$header-height-lg} - 2.25rem)

.pp-ico,
.pp-landing,
.pp-category
  display: inline-block
  speak: none
  text-decoration: inherit
  text-transform: none
  text-rendering: auto
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-weight: normal

.align-middle
  align-self: center
  display: flex
  align-items: center

.w-90
  width: 90%!important

.w-20
  width: 20%!important

.min-w-2rem
  min-width: 2rem

.single-line
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.height-2
  height: 2rem
  line-height: 1

.line-clamp-2
  height: auto
  max-height: 2.65rem
  overflow: hidden
  display: block
  display: -webkit-box!important
  line-clamp: 2
  -webkit-line-clamp: 2
  /* autoprefixer: off */
  -webkit-box-orient: vertical
  text-overflow: ellipsis
  line-height: 1.15rem
  text-wrap: wrap
  white-space: break-spaces

.cursor-grab
  cursor: grab

.cursor-grabbing
  cursor: grabbing
  width: 100%
  position: absolute
  left: 0
  display: block
  height: 100%
  right: 0
  top: 0

.cursor-pointer
  cursor: pointer!important

.cursor-default
  cursor: default!important

.pp-block
  @include pp-block

.hidden
  display: none!important

.menu-horizontal
  display: inline-block
  margin: 0
  padding: 0

.menu-horizontal-list
  display: inline-block
  vertical-align: top
  div
    display: inline

.gradient-hide
  width: 100%
  position: absolute
  bottom: 0
  height: 1.2rem
  margin-left: -1px
  background: linear-gradient(transparent, var(--lot-bg-color))

.pp-visited
  margin-right: auto
  width: 3rem
  height: 3rem
  border-radius: 50%
  left: -1.7rem
  top: -1.5rem
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  &:before
    position: absolute
    right: 0.25rem
    top: 1.4rem
    color: $pp-white

.image-placeholder
  font-size: 5rem
  color: var(--image-placeholder-color)
  display: flex
  align-items: center
  justify-content: center
  width: 100%

.line-height-1
  line-height: 1

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none

/* Firefox */
input[type=number]
  -moz-appearance: textfield

.m--4
  margin: -1rem

.ms--1
  margin-left: -0.25rem

.w-10rem
  width: 10rem

.pp-plan_private:before
  content: $plan_private

.gray-text
  color: $pp-gray!important

.blue-text
  color: $pp-blue!important

.no-border
  border: none!important

.text-yellow
  color: $pp-yellow!important

.skeleton-icon
  font-size: 1rem
  color: $gray-EEEEEE!important
  padding: .25rem .25rem .25rem .55rem

.show-more
  cursor: pointer
  color: $orange-FC4A1A
  text-transform: uppercase
  font-size: .75rem

.fs-1rem
  font-size: 1rem!important

.fs-1-5rem
  font-size: 1.5rem!important

.fs-075
  font-size: 0.75rem!important

.fs-085
  font-size: 0.85rem!important

.fs-095
  font-size: 0.95rem!important

.h025rem
  height: .25rem

.h25rem
  height: 2.5rem

.fw-100
  font-weight: 100!important

.fw-500
  font-weight: 500!important

.link-light
  color: $link-light

.link-dark
  color: var(--inverted-link-color)

.text-inverted
  color: var(--inverted-text-color)

.form-control.code-snip
  border: none
  height: auto
  button
    position: absolute
    right: 1rem
    top: 0
    bottom: 0

.discount-range
  margin: 0 5rem

.prices
  text-align: center
  margin-bottom: 1rem
  &__original
    color: $pp-gray
  &__new
    color: var(--masonry-price)
    font-weight: bold
    font-size: 1.3rem
  &__period
    color: $pp-gray

.v-code
  border-bottom: 1px solid $gray-CCCCCC

.trans-cat
  background-color: var(--trans-cat)

.cat-list
  text-decoration: none

i.telegram
  background-color: $telegram-color
  color: $pp-white!important
  border-radius: 50%
  width: 1.2rem
  height: 1.2rem
  display: inline-flex
  font-size: .65rem!important
  justify-content: center
  align-items: center
  margin: 0 0.25rem 0 0
  padding-right: 0.1rem!important

@each $category in $categories
  .cat-#{$category}
    border-color: var(--cat-#{$category})!important
    &:after
      color: var(--cat-#{$category})!important
    .multilevel__category__child i
      background: var(--cat-#{$category})!important

.share-publish
  margin: 0 0 2rem 0
  button
    background: none
    border: none
    margin-right: 1rem
  span
    font-size: 0.875rem
    color: $pp-gray

.background-transparent
  background-color: transparent!important

.border-none
  border: none!important

.h-3rem
  height: 3rem

.h-4rem
  height: 4rem

.letter-spacing-2
  letter-spacing: 2px

.unset-all
  all: unset

.dropdown.show
  .dropdown-menu
    box-shadow: var(--bs-dropdown-box-shadow)

.blur
  filter: blur(5px)

.z-1200
  z-index: $zindex-mobile-price

.box-shadow
  box-shadow: var(--box-shadow)

.pointer-events-none
  pointer-events: none

.rotate-90
  transform: rotate(90deg)

.rotate-180
  transform: rotate(180deg)

.clearfix
  clear: both
