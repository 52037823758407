@import ../base/variables
@import ../base/mixins
#createpp textarea:empty:before
  content: attr(placeholder)
  display: block

#createpp pre
  background: #EEE
  padding: 5px
  width: 100%

.textarea-wrapper
  border-bottom: 1px solid $pp-yellow
  background: var(--form-input-bg)
  position: relative
  .pp-lang
    transform: scale(1.5)
    position: absolute
    right: .5rem
  .description-lang
    right: 0
    border: 1px solid var(--border-color)
    position: relative
    border-bottom: none
  &:focus-within
    border-color: $input-focus-border-color
    box-shadow: $input-focus-box-shadow
  div
    &:before
      font-size: 1.1rem
      font-style: italic
  .and-or
    position: absolute
    right: -1px
    background: var(--form-input-bg)
    padding: 0 1rem
    display: block
    margin-top: -0.5rem
    margin-right: 1px
    letter-spacing: 0.78px
    text-transform: uppercase
    font-weight: 500
    +rem("font-size", 14px)
    color: $pp-yellow
    z-index: 1
  &.error
    .description-lang
      border-color: $pp-error
  textarea
    border: 1px solid transparent
    width: 100%
    background: var(--form-input-bg)
    min-height: 10rem
    outline: 0
    padding-right: 3rem
