@keyframes rotating
  from
    transform: rotate(0deg)
  to
    transform: rotate(90deg)

@keyframes slide-left
  0%
    opacity: 0
    transform: translateX(-100%)
  100%
    opacity: 1
    transform: translateX(0)

@keyframes mouse-move
  21%
    transform: translate(0,4px) rotateZ(-10deg)
  56%
    transform: translate(10px,1px) rotateZ(10deg)
  98%
    transform: translate(0,0)

@keyframes mouse-click
  21%
    fill: $pp-gray
  98%
    fill: $pp-white

@keyframes introduceIcon
  0%
    color: $pp-gray
  100%
    color: $pp-yellow

@keyframes iconHighlightRed
  0%
    color: $pp-yellow
  50%
    color: $pp-orange
  100%
    color: $pp-yellow

@keyframes iconHighlightGreen
  0%
    color: $pp-yellow
  50%
    color: $pp-filters
  100%
    color: $pp-yellow

@keyframes iconHighlightGreenGray
  0%
    color: $pp-white
  50%
    color: $pp-yellow
  100%
    color: $pp-white

@keyframes badgeHighlightRedGray
  0%
    background-color: $pp-gray
  50%
    background-color: $pp-orange
  100%
    background-color: $pp-gray

@keyframes shakeAnimation
  0%
    transform: rotate(0)
  15%
    transform: rotate(5deg)
  30%
    transform: rotate(-5deg)
  45%
    transform: rotate(4deg)
  60%
    transform: rotate(-4deg)
  75%
    transform: rotate(2deg)
  85%
    transform: rotate(-2deg)
  92%
    transform: rotate(1deg)
  100%
    transform: rotate(0)

@keyframes iconHighlightPink
  0%
    color: $pp-yellow
  50%
    color: $pp-pink
  100%
    color: $pp-yellow

@keyframes colorEase
  0%
    color: $pp-yellow
  50%
    color: darken($pp-yellow, 15%)
  100%
    color: $pp-yellow

@keyframes opacityAnimate
  from
    opacity: 0
  to
    opacity: 1

@keyframes slideUp
  0%
    transform: translateY(100%)
    opacity: 0
  20%
    transform: translateY(0)
    opacity: 1
  80%
    transform: translateY(0)
    opacity: 1
  100%
    transform: translateY(-100%)
    opacity: 0
