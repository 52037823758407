.filter
  &__card
    background-color: var(--lot-bg-color)
    border: 2px solid var(--border-color)
    margin: 0 0 #{$grid-gutter-width} 0
    line-height: 1
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between
    overflow: hidden
    &:hover
      border: 2px solid $pp-filters
      .filter__open
        opacity: 1
    &.fetching
      &:hover
        border: 2px solid var(--border-color)
    table
      margin-bottom: 0
      td
        vertical-align: middle
    &.disabled
      h5,
      .filter__category,
      tr
        opacity: 0.5
  &__category
    border-bottom: 1px solid var(--border-color)
    display: flex
    font-size: .85rem
    background: var(--filter-value-bg)
    .category-icon
      border-left: 4px solid
      padding-left: 0.5rem
    .category-title
      text-transform: none
      font-weight: normal
  &__count
    white-space: nowrap
    align-items: center
  &__value
    min-height: 2rem
    background: var(--filter-value-bg)
    border: 2px solid var(--filter-value-bg)
    display: inline-flex
    padding: 0.25rem
    align-items: center
    min-width: 3rem
  &__footer
    border-top: 1px solid var(--border-color)
    padding: .5rem
    background: var(--filter-value-bg)
    display: flex
    align-items: center
  &__button
    font-size: .8rem
    font-weight: bold
    i
      color: $pp-gray
  &__notification
    background: none
    border: none
    padding: 0
  &__loader
    position: absolute
    top: 0
    left: 0
    right: 0
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    background: $pp-white
    height: 100%
    cursor: default
  &__open
    opacity: 0
    display: flex
    justify-content: center
    margin-bottom: 0.25rem
    transition: opacity 0.5s ease
    button
      border: none
      &.filter-open
        background: var(--filter-value-bg)
        color: $pp-gray!important

.tooltip-inner
  font-size: .85rem

.filter-collapse
  @include pp-block
  color: $pp-icon
  +rem('font-size', 11px)
  text-transform: uppercase
  font-weight: 200
  margin-bottom: .5rem
  padding: .75rem .75rem 1rem
  .invalid-feedback
    display: none
  h6
    color: var(--inverted-text-color)
    font-weight: bold
    text-transform: uppercase
    +rem("font-size", 12px)
    letter-spacing: 1.33px
    display: flex
    align-items: center
    margin: 0
    &.filter-toggle
      cursor: pointer
      margin: 0
      i
        transition: ease 200ms
      &.dropdown-open
        .pp-angle-right
          transform: rotate(90deg)
    .pp-landing
      color: $pp-yellow
      font-size: initial
      font-weight: initial
      padding-right: .25rem
      &.pp-angle-right
        font-weight: bold
        font-size: 1.5rem
        margin-bottom: .25rem
        padding: 0
        margin-left: -.2rem
    &.style-normal
      text-transform: none
      font-weight: normal
      letter-spacing: normal
      font-size: 1rem
      display: flex
      align-items: baseline
  .fieldset-label
    margin-top: 1rem

.classfs,
.classfs-new
  +rem('font-size', 12px)
  text-transform: uppercase
  color: $pp-gray
  font-weight: 100
  margin-bottom: 0.7rem

.classfs-new
  color: $pp-yellow
  .badge-default
    background-color: $pp-yellow
.filter-control
  bottom: 0
  right: 1rem
  left: 1rem
  position: sticky
  z-index: 4
.filter-block
  position: sticky
  top: 80px
  margin-bottom: 105px
  h6
   .fa-angle-right
    font-weight: bold
    font-size: 1.5rem
    margin-bottom: .25rem

.filter-by-selection
  display: flex
  align-items: center
  cursor: pointer
  width: auto
  height: auto!important
  min-height: 3rem
  padding: 0.5rem
  span
    text-transform: none
    font-size: .8rem
    color: $pp-black
    flex-wrap: wrap
    font-weight: 500
    padding: 0.2rem 0.5rem 0.1rem

.filter-fieldset-label
  margin-top: 1rem
  border: 1px solid var(--form-input-border)
  background-color: var(--form-input-bg)
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none)
  background-repeat: no-repeat
  background-position: right 0.5rem top 1.2rem
  background-size: $form-select-bg-size

.filter-header
  cursor: pointer
  padding: 1rem

.icon-collapse
  font-size: 1.2rem

.filter-body
  padding: 0.25rem 1rem

.btn-close-chip
  border: none
  background: none
  margin-left: 0.5rem
  color: $pp-white

.badge-filter
  background-color: $brand-color!important
  color: $pp-white!important
  border: 1px solid transparent
  font-size: 0.85rem
  font-weight: bold
  border-radius: 20px
  padding: 8px 15px
  cursor: pointer
  margin: 0 5px 5px 0
  display: inline-flex
  align-items: center
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease
  text-align: center

.filter-chips
  background-color: var(--bs-btn-bg)
  color: var(--bs-btn-color)
  border: 1px solid var(--bs-btn-border-color)
  border-radius: 20px
  padding: 0.4rem 0.8rem
  display: inline-flex
  align-items: center
  cursor: pointer
  margin-bottom: .25rem
  margin-right: .25rem
  span
    font-weight: bold
  &:hover
    background-color: $brand-color
    color: $pp-white
    transform: scale(1.05)

  &.filter-disabled
    background-color: var(--disabled-bg-color)
    border: 1px solid var(--disabled-border-color)
    color: var(--disabled-text-color)
    cursor: not-allowed
    opacity: 0.6
    pointer-events: none
    &:hover
      background-color: var(--disabled-bg-color)
      border: 1px solid var(--disabled-border-color)
